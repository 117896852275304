<template>
    <!-- 入口文件 -->
    <div class="MarketManagement">
        <div class="tabs">
            <ul>
                <li @click="_tabsActive(0)" :class="tabsActive == 0 ? 'active' : ''">
                    市场活动
                </li>
                <li @click="_tabsActive(1)" :class="tabsActive == 1 ? 'active' : ''">
                    兼职管理
                </li>
                <li @click="_tabsActive(2)" :class="tabsActive == 2 ? 'active' : ''">
                    兼职等级
                </li>
                <li @click="_tabsActive(3)" :class="tabsActive == 3 ? 'active' : ''">
                    合作渠道
                </li>
                <li @click="_tabsActive(4)" :class="tabsActive == 4 ? 'active' : ''">
                    引流订单
                </li>
                <li @click="_tabsActive(5)" :class="tabsActive == 5 ? 'active' : ''">
                    数据统计
                </li>
            </ul>
        </div>
        <div class="container">
            <div
                    class="content"
                    v-if="tabsActive == 0"
                    style="background: #F0F0F8;padding: 0"
            >
                <activityWork></activityWork>
            </div>
            <div class="content" v-if="tabsActive == 1">
                <management></management>
            </div>
            <div class="content" v-if="tabsActive == 2">
                <staffLevel></staffLevel>
            </div>
            <div class="content" v-if="tabsActive == 3">
                <channel></channel>
            </div>
            <div class="content" v-if="tabsActive == 4">
                <coursePackage></coursePackage>
            </div>
            <div class="content" v-if="tabsActive == 5">
                <report></report>
            </div>
        </div>
    </div>
</template>

<script>
    import activityWork from 'pages/MarketManagement/activityWork'
    import management from 'pages/MarketManagement/management'
    import staffLevel from 'pages/MarketManagement/staffLevel'
    import channel from 'pages/MarketManagement/channel'
    import coursePackage from 'pages/MarketManagement/coursePackage'
    import report from 'pages/MarketManagement/report'

    export default {
        name: 'MarketManagement',
        components: {
            activityWork,
            management,
            staffLevel,
            channel,
            coursePackage,
            report
        },
        data() {
            return {
                tabsActive: 0,
                container: ''
            }
        },
        mounted() {
            this.container = document.getElementsByClassName('MarketManagement')[0]
        },
        methods: {
            _tabsActive(index) {
                this.tabsActive = index
                this.selectTemplateId = ''
                this.setWorkBook = -1
                this.isNew = false
            }
        }
    }
</script>

<style scoped lang="less">
    .MarketManagement {
        height: 100%;

        .tabs {
            border-bottom: 1px solid #ccd1dc;
            border-left: 1px solid #ccd1dc;
            border-top-left-radius: 5px;

            ul {
                li {
                    display: inline-block;
                    width: 85px;
                    padding: 6px 0;
                    text-align: center;
                    border: 1px solid #ccd1dc;
                    border-bottom: none;
                    cursor: pointer;
                    border-left: 0;

                    &:first-child {
                        border-top-left-radius: 5px;
                    }

                    &:last-child {
                        border-top-left-radius: 0;
                        border-top-right-radius: 5px;
                    }
                }

                .active {
                    background: #1890ff;
                    color: #fff;
                    border: 1px solid #1890ff;
                }
            }
        }
    }

    .container {
        min-height: calc(~'100% - 60px');
        margin-top: 15px;
        display: flex;
        justify-content: space-between;

        .content {
            width: 100%;
            min-height: 100%;
            padding: 15px;
            background: #fff;
            border-radius: 4px;
        }
    }
</style>
