<template>
  <div class="channel">
    <div v-if="!isNew">
      <a-select class="select" v-model="SearchCity" placeholder="城市">
        <a-select-option disabled selected hidden value=""
          >城市</a-select-option
        >
        <a-select-option
          :value="item.CityName"
          v-for="(item, index) in CityName"
          >{{ item.CityName }}</a-select-option
        >
      </a-select>

      <a-input placeholder="搜索渠道名称" class="info" v-model="SearchName" />
      <a-button class="reset-btn" @click="resetBtn()">重置</a-button>
      <a-button type="primary" @click="_search">查询</a-button>
      <a-button type="primary" class="create-btn" @click="_showNewPage"
        >创建</a-button
      >
      <div class="columns">
        <a-table
          bordered
          :columns="tableColumns"
          :dataSource="tableData"
          :pagination="false"
        >
          <div slot="Operation" slot-scope="text, index">
            <i
              class="iconfont icon-bianji icon-btn"
              style="font-size: 20px; margin-right: 10px"
              @click.stop="_setItem(index)"
            ></i>
            <a-popconfirm
              title="您确定要删除吗?"
              @confirm="_delTableRow(index)"
              okText="确定"
              cancelText="取消"
            >
              <i
                class="iconfont icon-shanchu- icon-btn"
                style="font-size: 20px"
              ></i>
            </a-popconfirm>
          </div>
        </a-table>
        <div style="margin: 20px 0; text-align: right" v-if="count > 10">
          <a-pagination
            showQuickJumper
            :defaultCurrent="1"
            :total="count"
            @change="_pageSchedule"
          />
        </div>
      </div>
    </div>
    <div v-if="isNew" style="flex: 1; min-height: 100%; min-weidth: 100%">
      <div class="new-page">
        <div class="new-page-header">
          <div class="title">
            {{ this.detailData ? '编辑' : '创建' }}合作渠道
          </div>
          <div class="btn">
            <a-button
              type="primary"
              style="width: 80px; margin-right: 10px"
              @click="_save"
              >确定</a-button
            >
            <a-button style="width: 80px" @click="_back">返回</a-button>
          </div>
        </div>
        <div class="container">
          基本信息
        </div>
        <div id="components-form-demo-advanced-search">
          <a-form class="ant-advanced-search-form">
            <a-row :gutter="20">
              <a-col :span="8">
                <a-form-item :label="'渠道名称'" :required="true">
                  <a-input
                    style="width: 250px;"
                    placeholder="请输入"
                    v-model="Name"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item :label="'联系人姓名'">
                  <a-input
                    style="width: 250px;"
                    placeholder="请输入"
                    v-model="ContactPerson"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item :label="'联系人电话'">
                  <a-input
                    style="width: 250px;"
                    placeholder="请输入"
                    v-model="ContactPhone"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item :label="'预计费用'">
                  <a-input
                    style="width: 250px;margin-left:10px"
                    placeholder="请输入"
                    v-model="ExpectFee"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item :label="'所在城市'">
                  <a-select
                    style="width: 250px;margin-left:13px"
                    v-model="City"
                    placeholder="请选择"
                  >
                    <a-select-option disabled selected hidden value=""
                      >请选择</a-select-option
                    >
                    <a-select-option
                      :value="item.CityName"
                      v-for="(item, index) in CityName"
                      >{{ item.CityName }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item :label="'创建人'">
                  <a-input
                    style="width: 250px;margin-left:28px"
                    v-model="CreateUserName"
                    disabled
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
          <p class="info-container" style="padding:0 10px 15px 10px">
            <span class="info-title">渠道Logo</span>
            <a-button style="position: relative;margin-left:25px"
              >上传图片
              <input
                type="file"
                style="position: absolute;width: 100%;height: 100%;opacity: 0;top:0;left:0"
                @change="_uploadLogo($event)"
            /></a-button>
          </p>
          <div v-if="this.Logo" class="info-logo">
            <viewer :images="[this.Logo]">
              <img :src="this.Logo" />
            </viewer>
            <span>您已上传渠道Logo图片</span>
            <i class="iconfont icon-shanchu- icon-btn" @click="_delLogo"></i>
          </div>
          <p class="info-container" style="padding:10px 10px 15px 10px">
            <span class="info-title">备注</span>
            <a-textarea
              style="margin-top:5px;height: 150px;resize: none"
              v-model="Remark"
              placeholder="请输入"
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Viewer from 'v-viewer' //图片操作
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
export default {
  name: 'channel',
  data () {
    return {
      index: 1,
      SearchCity: '',
      SearchName: '',
      Name: '',
      City: '',
      Logo: '',
      CreateUserName: '',
      userName: '',
      SelectCity: '',
      ExpectFee: '',
      ContactPerson: '',
      ContactPhone: '',
      Remark: '',
      LogoName: '',
      detailData: '',
      // isShow: true,
      visible: false,
      isNew: false,
      expand: false,
      Page: 1,
      count: 0,
      isNewAdd: 0,
      CityName: [],
      tableData: [],
      tableColumns: [
        {
          title: '序号',
          dataIndex: 'sNumber',
          width: 80,
          align: 'center'
        },
        {
          title: '合作渠道',
          dataIndex: 'Name',
          width: 300,
          align: 'center'
        },
        {
          title: '基本费用',
          dataIndex: 'ExpectFee',
          width: 200,
          align: 'center'
        },
        {
          title: '联系人',
          dataIndex: 'ContactPerson',
          width: 250,
          align: 'center'
        },
        {
          title: '联系人电话',
          dataIndex: 'ContactPhone',
          width: 250,
          align: 'center'
        },
        {
          title: '城市',
          dataIndex: 'City',
          width: 200,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'Remark',
          width: 350,
          align: 'center'
        },
        {
          title: '创建人',
          dataIndex: 'CreateUserName',
          width: 300,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'CreateTime',
          width: 400,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'Operation',
          width: 200,
          align: 'center',
          scopedSlots: {
            customRender: 'Operation'
          }
        }
      ]
    }
  },
  computed: {},
  created () {
    this._getInfo()
  },
  mounted () {
    this.$axios.post(8000002, {}, res => {
      if (res.data.code == 1) {
        this.CityName = res.data.data
      }
    })
    this._getUserName()
  },
  methods: {
    // 初始化列表
    _getInfo () {
      this.$axios.post(
        8000003,
        {
          PageSize: 10
        },
        res => {
          if (res.data.code == 1) {
            this.count = res.data.count
            this.tableData = res.data.data
            this.tableData.map((res, index) => {
              res.sNumber = index + 1
            })
          }
        }
      )
    },
    _getUserName () {
      this.userName = JSON.parse(window.sessionStorage.getItem('userInfo'))
      this.CreateUserName = this.userName.name
    },
    resetBtn () {
      ;(this.SearchCity = ''), (this.SearchName = ''), (this.index = 1)
    },
    _pageSchedule (pageNumber) {
      this._search(pageNumber)
      console.log(pageNumber)
    },
    _search (Page) {
      console.log(Page)
      this.$axios.post(
        8000003,
        {
          City: this.SearchCity,
          Name: this.SearchName,
          Page: Page,
          PageSize: 10
        },
        res => {
          if (res.data.code == 1) {
            this.count = res.data.count
            this.tableData = res.data.data
            this.tableData.map((res, index) => {
              if (Page * 0 != 0) {
                res.sNumber = index + 1
              } else {
                res.sNumber = (Page - 1) * 10 + index + 1
              }
            })
          } else {
            this.tableData = []
            this.count = 0
          }
        }
      )
    },
    _setItem (i) {
      console.log(i.Id)
      this.isNew = true
      this.$axios.post(
        8000010,
        {
          Id: i.Id
        },
        res => {
          if (res.data.code == 1) {
            this.detailData = res.data.data
            this.Name = res.data.data.Name
            this.ExpectFee = res.data.data.ExpectFee
            this.ContactPerson = res.data.data.ContactPerson
            this.ContactPhone = res.data.data.ContactPhone
            this.City = res.data.data.City
            this.Remark = res.data.data.Remark
            this.Logo = res.data.data.Logo
            this.CreateUserName = res.data.data.CreateUserName
          }
        }
      )
      // if(i) {
      //  this.isShow =  false;
      // };
    },
    _save () {
      this.isNew = false
      this.visible = false
      let data = {
        Name: this.Name,
        ContactPerson: this.ContactPerson,
        ContactPhone: this.ContactPhone,
        ExpectFee: this.ExpectFee,
        City: this.City,
        Logo: this.Logo,
        Remark: this.Remark,
        CreateUserId: this.Id,
        CreateUserName: this.CreateUserName
      }
      if (this.detailData) {
        data.Id = this.detailData.Id
        this.$axios.post(8000011, data, res => {
          if (res.data.code == 1) {
            this.$message.success('修改成功')
            this._getInfo()
            this._onClose()
          } else {
            this.$message.error(res.data.msg)
          }
        })
      } else {
        this.$axios.post(8000011, data, res => {
          if (res.data.code == 1) {
            this.$message.success('添加成功')
            this._getInfo()
            this._onClose()
          } else {
            this.$message.error(res.data.msg)
          }
        })
      }
    },
    _uploadLogo (e) {
      this.$message.loading('图片正在上传中，请耐心等候')
      this.__uploadFile__(e.target.files).then(res => {
        this.Logo = res[0].FileUrl
        this.LogoName = res[0].FileName
        console.log(res[0])
      })
    },
    _onClose () {
      this.visible = false
      this.SearchName = ''
      this.ExpectFee = ''
      this.ContactPerson = ''
      this.ContactPhone = ''
      this.SearchCity = ''
      this.Remark = ''
      this.detailData = ''
      this.Name = ''
      this.City = ''
      this.Logo = ''
    },
    _showNewPage () {
      this.isNew = true
      this.isNewAdd = 1
      this._getUserName()
    },
    _back () {
      this.isNew = false
      this.Name = ''
      this.ContactPerson = ''
      this.ContactPhone = ''
      this.ExpectFee = ''
      this.City = ''
      this.SelectCity = ''
      this.Remark = ''
      this.Logo = ''
      // this.isShow =  true;
      this.CreateUserName = ''
      this.detailData = undefined
    },
    _delTableRow (index) {
      this.$axios.post(8000018, { Id: index.Id }, res => {
        if (res.data.code == 1) {
          this.$message.success('删除成功')
          this._getInfo()
          // this.tableData.splice(index,1);
        }
      })
    },
    _delLogo () {
      this.Logo = ''
      this.LogoName = ''
      this.$message.success('删除渠道Logo图片成功')
    }
  }
}
</script>

<style scoped lang="less">
.channel {
  .select {
    width: 150px;
    margin-right: 10px;
  }

  .columns {
    margin-top: 20px;
    margin-right: 20px;
  }

  .info {
    width: 200px;
    margin-right: 10px;
  }

  .create-btn {
    width: 80px;
    float: right;
    margin-right: 30px;
  }

  .reset-btn {
    margin-right: 10px;
  }

  .new-page-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    .title {
      margin-right: auto;
      font-size: 16px;
    }

    .btn {
      margin-left: auto;
    }
  }

  .container {
    margin-top: 30px;
    margin-left: 10px;
    font-size: 15px;

    .info-container {
      display: flex;
      align-items: center;

      .info-title {
        width: 70px;
        white-space: nowrap;
      }
    }
  }
  .info-logo {
    display: flex;
    height: 66px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin: 0 10px;
    align-items: center;
    margin-bottom: 10px;
    img {
      width: 50px;
      height: 50px;
      overflow: hidden;
    }
    span {
      margin-left: 20px;
      color: rgba(0, 0, 0, 0.4);
    }
    i {
      margin-left: auto;
      margin-right: 5px;
    }
  }
}

.row {
  margin-bottom: 20px;

  .label {
    display: inline-block;
    width: 70px;
    white-space: nowrap;
  }

  .row-text {
    width: 350px;
    margin-left: 25px;
  }
}

.alert-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;

  button {
    width: 120px;
  }
}

.ant-advanced-search-form {
  padding: 25px 25px 15px 10px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
</style>
