<template>
  <div id="course">
    <div class="header">
      <a-tree-select
        style="min-width: 150px;margin-right: 10px;"
        :treeData="treeDataSchool"
        treeNodeFilterProp="title"
        :value="schoolVal"
        @change="onChangeSchool"
        treeCheckable
        :showCheckedStrategy="SHOW_PARENT"
        placeholder="客户归属中心"
      />
      <a-select
        style="width: 150px;margin-right: 10px"
        v-model="parseInfo"
        showSearch
        allowClear
        placeholder="活动负责人"
        optionFilterProp="children"
      >
        <a-select-option
          :value="item.value"
          v-for="(item, index) in leaderList"
          >{{ item.name }}</a-select-option
        >
      </a-select>
      <!-- 日期组件 -->
      <a-range-picker
        style="width: 200px; margin-right: 10px"
        format="YYYY-MM-DD"
        :placeholder="['开始时间', '结束时间']"
        :ranges="{
          本月: [moment().startOf('month'), moment().endOf('month')],
          上月: [
            moment(
              moment()
                .month(moment().month() - 1)
                .startOf('month')
            ),
            moment(
              moment()
                .month(moment().month() - 1)
                .endOf('month')
            )
          ],
          上上月: [
            moment(
              moment()
                .month(moment().month() - 2)
                .startOf('month')
            ),
            moment(
              moment()
                .month(moment().month() - 2)
                .endOf('month')
            )
          ]
        }"
        @change="ChangeSearchTime"
      >
        <a-tooltip slot="suffix"><a-icon type="clock-circle"/></a-tooltip>
      </a-range-picker>
      <a-input
        placeholder="请输入手机号"
        v-model="SearchTel"
        style="width: 150px"
      >
        <a-tooltip slot="suffix"><a-icon type="search"/></a-tooltip>
      </a-input>
      <a-button @click="resetBtn()" style="margin: 0 10px">重置</a-button>
      <a-button type="primary" @click="_info(1)">查询</a-button>
    </div>
    <div class="info">
      <div class="content-icon">
        <a-icon type="bar-chart" class="logo" />
      </div>
      <div class="info-main">
        <div class="title">有效名单</div>
        <div class="content">{{ countData.yx }}</div>
        <div class="title">重复名单：{{ countData.exist_num }}</div>
      </div>
      <div class="info-main">
        <div class="title">订单数量</div>
        <div class="content">{{ countData.order_num }}</div>
      </div>
      <div class="info-main">
        <div class="title">订单总金额(元)</div>
        <div class="content">{{ countData.order_fee }}</div>
      </div>
      <div class="info-main">
        <div class="title">今日名单数</div>
        <div class="content">{{ countData.today_num }}</div>
        <div class="title">昨日名单数：{{ countData.yesterday_num }}</div>
      </div>
      <div class="info-main">
        <div class="title">今日订单数量</div>
        <div class="content">{{ countData.today_order }}</div>
        <div class="title">昨日订单数：{{ countData.yesterday_order }}</div>
      </div>
      <div class="info-main">
        <div class="title">今日总金额</div>
        <div class="content">{{ countData.today_fee }}</div>
        <div class="title">昨日总金额：{{ countData.yesterday_fee }}</div>
      </div>
    </div>
    <a-table
      bordered
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="false"
      style="margin-top:20px"
    >
      <div slot="sNumber" slot-scope="text, record, index">
        {{ page * 10 + index + 1 }}
      </div>

      <div
        slot="Phone"
        slot-scope="text, record"
        @click="_skipSkim(record.StudentId, record.StudentName)"
      >
        <span style="color: #2994ff;cursor:pointer">{{ record.Phone }}</span>
      </div>
    </a-table>
    <div style="margin: 20px 0;text-align: right" v-if="count > 10">
      <a-pagination
        showQuickJumper
        :defaultCurrent="1"
        :total="count"
        @change="_PageChange"
      />
    </div>
  </div>
</template>

<script>
import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
import moment from 'moment'
export default {
  name: 'course',
  data () {
    return {
      centerName: '',
      leaderList: '',
      StartDate: '',
      EndDate: '',
      SearchTel: '',
      parseInfo: '',
      treeDataSchool: [],
      schoolVal: [],
      parseList: [],
      transXY: '',
      count: 0,
      SHOW_PARENT,
      tableData: [],
      tableColumns: [
        {
          title: '序号',
          dataIndex: 'sNumber',
          width: 50,
          align: 'center',
          scopedSlots: {
            customRender: 'sNumber'
          }
        },
        {
          title: '客户姓名',
          dataIndex: 'StudentName',
          width: 150,
          align: 'center'
        },
        {
          title: '客户号码',
          dataIndex: 'Phone',
          width: 150,
          align: 'center',
          scopedSlots: {
            customRender: 'Phone'
          }
        },
        {
          title: '所属中心',
          dataIndex: 'SchoolName',
          width: 150,
          align: 'center'
        },
        {
          title: '订单名称',
          dataIndex: 'Title',
          width: 150,
          align: 'center'
        },
        {
          title: '交易时间',
          dataIndex: 'TimeEnd',
          width: 150,
          align: 'center'
        },
        {
          title: '活动负责人',
          dataIndex: 'Person',
          width: 150,
          align: 'center'
        },
        {
          title: '支付金额(元)',
          dataIndex: 'TotalFee',
          width: 100,
          align: 'center'
        }
      ],
      infoData: '',
      countData: '',
      page: 0
    }
  },
  created () {
    // 获取中心数据接口
    this.$axios.get(8008, {}, res => {
      if (res.data.code == 1) {
        this.treeDataSchool = res.data.data
      } else {
        this.$message.error(res.data.msg)
      }
    })

    this.$axios.get(5836186, {}, res => {
      if (res.data.code == 1) {
        this.leaderList = res.data.data
      } else {
        this.$message.error(res.data.msg)
      }
    })
    this._info(1)
  },
  mounted () {},
  methods: {
    _info (page) {
      this.$message.loading(
        '查询数据量较大，且只显示你权限范围的结果，请耐心等候',
        0
      )
      this.$axios.get(
        5836185,
        {
          SchoolId: this.schoolVal.join(','),
          PersonId: this.parseInfo,
          StartDate: this.StartDate,
          EndDate: this.EndDate,
          phone: this.SearchTel,
          page: page,
          limit: 10
        },
        res => {
          if (res.data.code == 1) {
            this.tableData = res.data.data
            this.countData = res.data.count_data
            this.count = res.data.count
            this.$message.destroy()
          } else {
            if (page == 1) {
              this.tableData = []
              this.count = 0
            }
            this.$message.destroy()
            this.$message.error(res.data.message)
          }
        }
      )
    },
    moment,
    ChangeSearchTime (time, timeString) {
      this.StartDate = timeString[0]
      this.EndDate = timeString[1]
    },
    resetBtn () {
      this.centerName = ''
      this.SearchTel = ''
      this.schoolVal = []
      this.parseInfo = ''
    },
    onChangeSchool (value) {
      this.schoolVal = value
      this.transXY = {}
    },
    _PageChange (num) {
      this.page = num - 1
      this._info(num)
    },
    _skipSkim (id, name) {
      let data = {
        type: 'student',
        name: name,
        id: id
      }
      window.parent.postMessage(data, '*')
    }
  }
}
</script>

<style scoped lang="less">
.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  height: 100px;
  width: 80%;
  border: 1px solid #e4e5e6;
  border-radius: 4px;
  .content-icon {
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    line-height: 60px;
    margin: 0 30px;
    border-radius: 50%;
    text-align: center;
    background: #2994ff;
    i {
      font-size: 30px;
      color: #fff;
    }
  }
  .info-main {
    display: flex;
    flex-direction: column;
    flex: 1;
    .title {
      white-space: nowrap;
      font-size: 14px;
      color: #999999;
    }
    .content {
      color: #2994ff;
      font-size: 14px;
    }
  }
}
</style>
