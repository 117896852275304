<template>
    <div
            class="activityWork"
            style="background: #fff;padding: 15px;border-radius: 4px;"
            v-if="!isNew"
    >
        <a-select
                class="select"
                v-model="searcheCity"
                placeholder="城市"
                @change="_ChangeCityInfo"
                showSearch
        >
            <a-select-option disabled selected hidden value="">城市</a-select-option>
            <a-select-option
                    :value="item.CityName"
                    v-for="(item, index) in CityName"
            >{{ item.CityName }}</a-select-option
            >
        </a-select>

        <a-select
                class="select"
                v-model="searcheStores"
                placeholder="所属中心"
                showSearch
                optionFilterProp="children"
        >
            <a-select-option disabled selected hidden value="">中心</a-select-option>
            <a-select-option :value="item.Id" v-for="(item, index) in CenterName">{{
                item.OrgName
                }}</a-select-option>
        </a-select>

        <a-range-picker
                class="info"
                style="width: 200px"
                format="YYYY-MM-DD"
                :placeholder="['开始时间', '结束时间']"
                @change="ChangeSearchTime"
        />
        <a-input placeholder="活动名称" class="info" v-model="activity_name" />
        <a-input
                placeholder="创建人/负责人"
                class="info"
                v-model="activity_person"
        />
        <a-button class="reset-btn" @click="resetBtn()">重置</a-button>
        <a-button class="reset-btn" type="primary" @click="serachBtn()"
        >查询</a-button
        >
        <a-button type="primary" class="create-btn" @click="_setItem"
        >新建</a-button
        >
        <!-- 首页列表 -->
        <div class="columns">
            <a-table
                    bordered
                    :columns="tableColumns"
                    :dataSource="tableData"
                    :pagination="false"
            >
                <div
                        slot="ActivityName"
                        slot-scope="text, index"
                        @click="_viewData(index)"
                >
                    <span style="color: #2994ff;cursor: pointer">{{ text }}</span>
                </div>
                <div slot="Operation" slot-scope="text, index">
                    <i
                            class="iconfont icon-bianji icon-btn"
                            style="font-size: 20px;margin-right: 10px"
                            @click.stop="_setItem(index)"
                    ></i>
                    <a-icon
                            type="bar-chart"
                            style="font-size: 20px;margin-right: 10px;cursor: pointer"
                            @click="_viewData(index)"
                    />
                    <a-popconfirm
                            title="你确定要复制嘛?"
                            style="font-size: 20px;margin-right: 10px"
                            okText="确定"
                            @confirm="_copy(index)"
                            cancelText="取消"
                    >
                        <a-icon type="copy" class="icon-btn" style="cursor: pointer" />
                    </a-popconfirm>
                    <a-popconfirm
                            title="您确定要删除吗?"
                            @confirm="_delTableRow(index)"
                            okText="确定"
                            cancelText="取消"
                    >
                        <i
                                class="iconfont icon-shanchu- icon-btn"
                                style="font-size: 20px;"
                        ></i>
                    </a-popconfirm>
                </div>
            </a-table>
            <div style="margin: 20px 0;text-align: right" v-if="count > 10">
                <!-- current= 当前页码 -->
                <a-pagination
                        showQuickJumper
                        :defaultCurrent="1"
                        :total="count"
                        @change="_PageChange"
                />
            </div>
        </div>
    </div>
    <div v-else-if="viewData" class="view-data">
        <div class="header">
            <div class="title flex">
                <span>{{ this.Name }}</span>
                <div style="margin-left:auto;">
                    <a-button
                            type="primary"
                            class="reset-btn"
                            style="width:80px;margin-right:20px"
                            @click="_dataBack"
                    >返回</a-button
                    >
                </div>
            </div>
            <div class="header-info">
                <div id="components-form-demo-advanced-search">
                    <a-form
                            class="ant-advanced-search-form"
                            style="padding: 0 25px 5px 10px;"
                    >
                        <a-row :gutter="230">
                            <a-col :span="8">
                                <a-form-item :label="'城市'">{{ this.City }}</a-form-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-item :label="'所属中心'">{{
                                    this.SchoolName
                                    }}</a-form-item>
                            </a-col>
                            <a-col :span="8"> </a-col>
                            <a-col :span="8">
                                <a-form-item :label="'所属来源'">{{ this.Source }}</a-form-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-item :label="'活动预计费用'">{{
                                    this.Money
                                    }}</a-form-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-item :label="'实际费用'">{{
                                    this.RealFee
                                    }}</a-form-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-item :label="'负责人'">{{ this.Person }}</a-form-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-item :label="'备注'">{{ this.Intro }}</a-form-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-item :label="'渠道'">{{
                                    this.Channel + this.Parttime
                                    }}</a-form-item>
                            </a-col>
                            <a-col :span="8" v-if="detailData && detailData.QrUrl">
                                <a-form-item :label="'小王子'">
                                    <!--{{this.detailData.QrUrl}}-->
                                    <div
                                            id="activity-qr-code"
                                            style="width: 120px;height: 120px;"
                                    ></div>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-form>
                    <!--                    <p class="info-container" style="padding:0 10px 20px 10px">
                                  <span class="info-title">备注：{{this.Intro}}</span>
                              </p>
                              <p class="info-container" style="padding:0 10px 20px 10px">
                                  <span class="info-title">渠道:{{this.Channel}}</span>
                              </p> -->
                </div>
            </div>
        </div>
        <div class="table-container">
            <div class="table" style="margin-bottom:25px">
                <div class="title">名单总计</div>
                <!-- 通过传字段名来判断点击事件 -->
                <!-- 名单总计列表 -->
                <div class="table-total">
                    <a-table
                            bordered
                            :columns="tableTotalColumns"
                            :dataSource="StudentData"
                            :pagination="false"
                    >
                        <div
                                slot="all"
                                slot-scope="text, record"
                                @click="_showNameTotal(record.Select[0])"
                        >
                            <span>{{ record.all }}</span>
                        </div>
                        <div
                                slot="yx"
                                slot-scope="text, record"
                                @click="_showNameTotal(record.Select[1])"
                        >
                            <span>{{ record.yx }}</span>
                        </div>
                        <div
                                slot="exist"
                                slot-scope="text, record"
                                @click="_showNameTotal(record.Select[2])"
                        >
                            <span>{{ record.exist }}</span>
                        </div>
                        <div
                                slot="contact"
                                slot-scope="text, record"
                                @click="_showNameTotal(record.Select[3])"
                        >
                            <span>{{ record.contact }}</span>
                        </div>
                        <div
                                slot="tracked"
                                slot-scope="text, record"
                                @click="_showNameTotal(record.Select[10])"
                        >
                            <span>{{ record.tracked }}</span>
                        </div>
                        <div
                                slot="visit"
                                slot-scope="text, record"
                                @click="_showNameTotal(record.Select[4])"
                        >
                            <span>{{ record.visit }}</span>
                        </div>
                        <div
                                slot="ty"
                                slot-scope="text, record"
                                @click="_showNameTotal(record.Select[5])"
                        >
                            <span>{{ record.ty }}</span>
                        </div>
                        <div
                                slot="deal"
                                slot-scope="text, record"
                                @click="_showNameTotal(record.Select[6])"
                        >
                            <span>{{ record.deal }}</span>
                        </div>
                        <div
                                slot="order_num"
                                slot-scope="text, record"
                                @click="_showNameTotal(record.Select[7])"
                        >
                            <span>{{ record.order_num }}</span>
                        </div>
                        <div
                                slot="order_fee"
                                slot-scope="text, record"
                                @click="_showNameTotal(record.Select[8])"
                        >
                            <span>{{ record.order_fee }}</span>
                        </div>
                        <!--
                                    <div slot="fee" slot-scope="text, record" @click="_showNameTotal(record.Select[8])">
                                        <span>{{record.fee}}</span>
                                    </div> -->
                    </a-table>
                </div>
            </div>
            <!-- 名单总计的右侧抽屉 -->
            <a-drawer
                    :title="'名单'"
                    placement="right"
                    width="1300"
                    :closable="true"
                    @close="_closeTotalShow"
                    :visible="TotalListShow"
            >
                <div class="table" style="margin-bottom:25px">
                    <div class="header" style="padding: 5px 10px 10px 0">
                        <a-select
                                style="width:120px;margin-right:10px"
                                v-model="trackListStateRight"
                                placeholder="名单状态"
                        >
                            <a-select-option disabled selected hidden value=""
                            >名单状态</a-select-option
                            >
                            <a-select-option
                                    :value="item.FieldValue"
                                    v-for="(item, index) in track_status_list"
                            >{{ item.FieldName }}</a-select-option
                            >
                        </a-select>
                        <a-select
                                style="width:150px;margin-right:10px"
                                v-model="centerListRight"
                                placeholder="所属中心"
                        >
                            <a-select-option disabled selected hidden value=""
                            >所属中心</a-select-option
                            >
                            <a-select-option
                                    :value="item.Id"
                                    v-for="(item, index) in OrgName"
                                    :key="index"
                            >{{ item.OrgName }}</a-select-option
                            >
                        </a-select>

<!--                         <a-select
                                style="width:150px;margin-right:10px"
                                v-model="personListRight"
                                placeholder="顾问姓名"
                                showSearch
                                optionFilterProp="children"
                        >
                            <a-select-option disabled selected hidden value=""
                            >顾问姓名</a-select-option
                            >
                            <a-select-option
                                    :value="item.Id"
                                    v-for="(item, index) in schoolPerson"
                                    :key="index"
                            >{{ item.Name }}</a-select-option
                            >
                        </a-select> -->

                        <a-input
                                style="width:150px;margin-right:10px"
                                placeholder="搜索顾问姓名"
                                v-model="personNameRight"
                        />

                        <a-select
                                style="width:100px;margin-right:10px"
                                v-model="listStateRight"
                                placeholder="状态"
                        >
                            <a-select-option disabled selected hidden value=""
                            >状态</a-select-option
                            >
                            <a-select-option
                                    :value="item.FieldValue"
                                    v-for="(item, index) in status_list"
                            >{{ item.FieldName }}</a-select-option
                            >
                        </a-select>
                        <a-range-picker
                                class="info"
                                style="width: 200px;margin-right: 10px"
                                format="YYYY-MM-DD"
                                :placeholder="['开始时间', '结束时间']"
                                @change="ChangeStuSearchTime"
                        />
                        <a-input
                                style="width:110px;margin-right:10px"
                                placeholder="搜索客户姓名"
                                v-model="customerNameRight"
                        />
                        <a-input
                                style="width:130px;margin-right:10px"
                                placeholder="搜索二维码来源"
                                v-model="QrcodeFromRight"
                        />
                        <a-button
                                style="margin-right:10px"
                                @click="_customerResetBtnRight()"
                        >重置</a-button
                        >
                        <a-button type="primary" @click="_scheduleTableRight()"
                        >查询</a-button
                        >
                        <!-- 对话框组件 -->
                        <a-button
                                type="primary"
                                @click="showModalRight"
                                style="margin-left: auto;"
                        >名单分配</a-button
                        >

                        <a-modal
                                v-model:visible="Rightvisible"
                                title="名单分配"
                                @ok="handleOkRight"
                                @cancel="handleCanleRight"
                        >
                            <div class="flex" style="margin-bottom: 15px">
                <span
                        class="row-label"
                        style="display: inline-block;width: 120px;"
                >选择中心</span
                >
                                <div style="flex: 1">
                                    <a-select
                                            style="margin-left:20px;width:350px"
                                            v-model="nameSelectedCenterR"
                                            placeholder="请选择"
                                            @change="_SelectedCenterR"
                                    >
                                        <a-select-option disabled selected hidden value=""
                                        >请选择</a-select-option
                                        >
                                        <a-select-option
                                                :value="item.Id"
                                                v-for="(item, index) in nameArrCenter"
                                        >{{ item.OrgName }}</a-select-option
                                        >
                                    </a-select>
                                </div>
                            </div>

                            <div class="flex" style="margin-bottom: 15px">
                <span
                        class="row-label"
                        style="display: inline-block;width: 120px;"
                >选择顾问</span
                >
                                <div style="flex: 1">
                                    <a-select
                                            style="margin-left:20px;width:350px"
                                            v-model="selectConsultantR"
                                            placeholder="请选择"
                                    >
                                        <a-select-option disabled selected hidden value=""
                                        >请选择</a-select-option
                                        >
                                        <a-select-option
                                                :value="item.Id"
                                                v-for="(item, index) in consultantArr"
                                        >{{ item.Name }}</a-select-option
                                        >
                                    </a-select>
                                </div>
                            </div>

                            <div class="flex">
                <span
                        class="row-label"
                        style="display: inline-block;width: 120px;"
                >备注</span
                >
                                <div style="flex: 1">
                                    <a-input
                                            style="margin-left:48px;width:350px"
                                            v-model="nameListNoteR"
                                            placeholder="请输入"
                                    />
                                </div>
                            </div>
                        </a-modal>
                    </div>
                    <div>
                        <!-- 右侧抽屉列表 -->
                        <a-table
                                bordered
                                :columns="ScheduleTable"
                                :dataSource="TotalSelectedData"
                                :pagination="false"
                                :rowSelection="{
                selectedRowKeys: NameListKeysRight,
                onChange: NameListChangeRight
              }"
                        >
                            <div
                                    slot="studentname"
                                    slot-scope="text, record"
                                    @click="_skipSkim(record.Id, record.StudentName)"
                            >
                                <span style="color: #2994ff">{{ record.StudentName }}</span>
                            </div>
                            <div
                                    slot="username"
                                    slot-scope="text, record"
                                    @click="_skipSkim(record.Id, record.StudentName)"
                            >
                                <span style="color: #2994ff">{{ record.UserName }}</span>
                            </div>
                            <div slot="operation" slot-scope="text, record">
                                <a-icon
                                        type="phone"
                                        style="cursor: pointer;font-size: 18px;margin-right: 10px;"
                                        @click="_relationVisible(record)"
                                />
                                <!-- <a-icon type="file-text" style="cursor: pointer;font-size: 18px;" @click="_tailVisible(record)" /> -->
                                <a-popover title="跟踪记录" trigger="hover" placement="left">
                                    <template slot="content">
                                        <div
                                                v-for="(item, index) in record.TrecordList"
                                                style="border-bottom: 1px solid #ddd;margin: 20px 0;padding: 10px;max-width:400px;"
                                        >
                                            <p style="font-size: 14px;">{{ item.RecordTit }}</p>
                                            <p
                                                    style="font-size: 12px;color: #aaa;margin: 10px 0;text-align: right"
                                            >
                                                创建于 {{ item.CreateTime }} by {{ item.PersonName }}
                                            </p>
                                        </div>
                                    </template>
                                    <a-icon
                                            type="file-text"
                                            style="cursor: pointer;font-size: 18px;"
                                            @click="_tailVisible(record)"
                                    />
                                </a-popover>
                            </div>
                        </a-table>
                    </div>
                    <div class="bottom-page" v-if="SchedulecountRight > 10">
                        <div class="bottom">
                            <a-input
                                    style="width:200px;margin-right:10px;"
                                    type="number"
                                    :min="10"
                                    addon-before="每页显示"
                                    addon-after="个"
                                    v-model="myPageSizeRight"
                                    @pressEnter="_pageChangeNumRight"
                            />
                            <a-pagination
                                    showQuickJumper
                                    :defaultCurrent="1"
                                    :total="SchedulecountRight"
                                    @change="_pageScheduleRight"
                                    :page-size.sync="myPageSizeNumRight"
                            />
                        </div>
                    </div>
                </div>
            </a-drawer>
            <div class="table" style="margin-bottom:25px">
                <div class="channel-header">
                    <div class="channel-title">渠道统计</div>
                    <!-- 导入excel -->
                    <div class="channel-download">
                        示例下载<a
                            href="http://ims.qitianzhen.cn/resource/Uploads/excel/data_example.xlsx"
                            target="_blank"
                            style="color:#0e90d2"
                    >[Excel示例下载]</a
                    >
                    </div>
                    <a-range-picker
                            class="info"
                            style="width: 200px;margin:0 10px 0 10px"
                            format="YYYY-MM-DD"
                            :placeholder="['开始时间', '结束时间']"
                            @change="DataSearchTime"
                    />
                    <a-button type="primary" @click="searchChannelData()">查询</a-button>
                </div>
                <div>
                    <!-- 渠道统计列表 -->
                    <a-table
                            bordered
                            :columns="statisticalColumns"
                            :dataSource="channelAll"
                            :pagination="false"
                    >
                        <div
                                slot="Operation"
                                slot-scope="text, record"
                                class="input"
                                style="position: relative"
                                v-if="record.Type === 'channel'"
                        >
                            <a-icon type="import" style="font-size: 18px;" />
                            <input
                                    type="file"
                                    name="file"
                                    accept="."
                                    multiple
                                    class="input_file"
                                    @click="_getId(record)"
                                    @change="handleChange($event, record.Id)"
                                    style="cursor: pointer;position: absolute;width:100%;height:100%;top:0;left:0"
                            />
                        </div>
                        <!-- 渠道统计跳转数据 -->
                        <div slot="parttime_yx" slot-scope="text, record" @click="_showParttimeDetail('yx',record.Id,record.Type)">
                            <span style="color: #2994ff">{{record.yx}}</span>
                        </div>
                        <div slot="parttime_exist" slot-scope="text, record" @click="_showParttimeDetail('exist',record.Id,record.Type)">
                            <span style="color: #2994ff">{{record.exist}}</span>
                        </div>
                        <div slot="parttime_contact" slot-scope="text, record" @click="_showParttimeDetail('contact',record.Id,record.Type)">
                            <span style="color: #2994ff">{{record.contact}}</span>
                        </div>
                        <div slot="parttime_visit" slot-scope="text, record" @click="_showParttimeDetail('visit',record.Id,record.Type)">
                            <span style="color: #2994ff">{{record.visit}}</span>
                        </div>
                        <div slot="parttime_ty" slot-scope="text, record" @click="_showParttimeDetail('ty',record.Id,record.Type)">
                            <span style="color: #2994ff">{{record.ty}}</span>
                        </div>
                        <div slot="parttime_deal" slot-scope="text, record" @click="_showParttimeDetail('deal',record.Id,record.Type)">
                            <span style="color: #2994ff">{{record.deal}}</span>
                        </div>
                        <div slot="parttime_order_num" slot-scope="text, record" @click="_showParttimeDetail('order_num',record.Id,record.Type)">
                            <span style="color: #2994ff">{{record.order_num}}</span>
                        </div>
                        <div slot="parttime_order_fee" slot-scope="text, record" @click="_showParttimeDetail('order_fee',record.Id,record.Type)">
                            <span style="color: #2994ff">{{record.order_fee}}</span>
                        </div>
                    </a-table>
                </div>
            </div>
            <div class="table" style="margin-bottom:25px">
                <div class="title">名单明细</div>
                <div class="header" style="padding: 5px 10px 10px 0">
                    <a-select
                            style="width:150px;margin-right:10px"
                            v-model="trackListState"
                            placeholder="名单状态"
                    >
                        <a-select-option disabled selected hidden value=""
                        >名单状态</a-select-option
                        >
                        <a-select-option
                                :value="item.FieldValue"
                                v-for="(item, index) in track_status_list"
                        >{{ item.FieldName }}</a-select-option
                        >
                    </a-select>
                    <a-select
                            style="width:150px;margin-right:10px"
                            v-model="centerList"
                            placeholder="所属中心"
                            showSearch
                            optionFilterProp="children"
                    >
                        <a-select-option disabled selected hidden value=""
                        >所属中心</a-select-option
                        >
                        <a-select-option
                                :value="item.Id"
                                v-for="(item, index) in OrgName"
                                :key="index"
                        >{{ item.OrgName }}</a-select-option
                        >
                    </a-select>

<!--                     <a-select
                            style="width:150px;margin-right:10px"
                            v-model="personList"
                            placeholder="顾问姓名"
                            showSearch
                            optionFilterProp="children"
                    >
                        <a-select-option disabled selected hidden value=""
                        >顾问姓名</a-select-option
                        >
                        <a-select-option
                                :value="item.Id"
                                v-for="(item, index) in schoolPerson"
                                :key="index"
                        >{{ item.Name }}</a-select-option
                        >
                    </a-select> -->
                    <a-input
                            style="width:150px;margin-right:10px"
                            placeholder="搜索顾问姓名"
                            v-model="personName"
                    />

                    <a-select
                            style="width:150px;margin-right:10px"
                            v-model="listState"
                            placeholder="状态"
                    >
                        <a-select-option disabled selected hidden value=""
                        >状态</a-select-option
                        >
                        <a-select-option
                                :value="item.FieldValue"
                                v-for="(item, index) in status_list"
                        >{{ item.FieldName }}</a-select-option
                        >
                    </a-select>

                    <a-range-picker
                            class="info"
                            style="width: 200px;margin-right: 10px"
                            format="YYYY-MM-DD"
                            :placeholder="['开始时间', '结束时间']"
                            @change="ChangeStuSearchTime"
                    />
                    <!--                    <a-select style="width:200px;margin-right:10px" v-model="orderType">
                                  <a-select-option disabled selected hidden value="">订单类型</a-select-option>
                                  <a-select-option :value="item" v-for="(item, index) in orderTypeArr">{{item}}</a-select-option>
                              </a-select> -->
                    <a-input
                            style="width:150px;margin-right:10px"
                            placeholder="搜索客户姓名"
                            v-model="customerName"
                    />
                    <a-input
                            style="width:150px;margin-right:10px"
                            placeholder="搜索二维码来源"
                            v-model="QrcodeFrom"
                    />
                    <a-button style="margin-right:10px" @click="_customerResetBtn()"
                    >重置</a-button
                    >
                    <a-button type="primary" @click="_scheduleTable()">查询</a-button>
                    <!-- 对话框组件 -->
                    <a-button type="primary" class="btn" @click="showModal"
                    >名单分配</a-button
                    >

                    <a-modal
                            v-model:visible="visible"
                            title="名单分配"
                            @ok="handleOk"
                            @cancel="handleCanle"
                    >
                        <div class="flex" style="margin-bottom: 15px">
              <span
                      class="row-label"
                      style="display: inline-block;width: 120px;"
              >选择中心</span
              >
                            <div style="flex: 1">
                                <a-select
                                        style="margin-left:20px;width:350px"
                                        v-model="nameSelectedCenter"
                                        placeholder="请选择"
                                        @change="_SelectedCenter"
                                >
                                    <a-select-option disabled selected hidden value=""
                                    >请选择</a-select-option
                                    >
                                    <a-select-option
                                            :value="item.Id"
                                            v-for="(item, index) in nameArrCenter"
                                    >{{ item.OrgName }}</a-select-option
                                    >
                                </a-select>
                            </div>
                        </div>

                        <div class="flex" style="margin-bottom: 15px">
              <span
                      class="row-label"
                      style="display: inline-block;width: 120px;"
              >选择顾问</span
              >
                            <div style="flex: 1">
                                <a-select
                                        style="margin-left:20px;width:350px"
                                        v-model="selectConsultant"
                                        placeholder="请选择"
                                >
                                    <a-select-option disabled selected hidden value=""
                                    >请选择</a-select-option
                                    >
                                    <a-select-option
                                            :value="item.Id"
                                            v-for="(item, index) in consultantArr"
                                    >{{ item.Name }}</a-select-option
                                    >
                                </a-select>
                            </div>
                        </div>

                        <div class="flex">
              <span
                      class="row-label"
                      style="display: inline-block;width: 120px;"
              >备注</span
              >
                            <div style="flex: 1">
                                <a-input
                                        style="margin-left:48px;width:350px"
                                        v-model="nameListNote"
                                        placeholder="请输入"
                                />
                            </div>
                        </div>
                    </a-modal>
                </div>
                <div>
                    <!-- 名单明细列表 -->
                    <a-table
                            bordered
                            :columns="ScheduleTable"
                            :dataSource="detailListData"
                            :pagination="false"
                            :rowSelection="{
              selectedRowKeys: NameListKeys,
              onChange: NameListChange
            }"
                    >
                        <div
                                slot="studentname"
                                slot-scope="text, record"
                                @click="_skipSkim(record.Id, record.StudentName)"
                        >
                            <span style="color: #2994ff">{{ record.StudentName }}</span>
                        </div>
                        <div
                                slot="username"
                                slot-scope="text, record"
                                @click="_skipSkim(record.Id, record.StudentName)"
                        >
                            <span style="color: #2994ff">{{ record.UserName }}</span>
                        </div>
                        <div slot="operation" slot-scope="text, record">
                            <a-icon
                                    type="phone"
                                    style="cursor: pointer;font-size: 18px;margin-right: 10px;"
                                    @click="_relationVisible(record)"
                            />
                            <!-- <a-icon type="file-text" style="cursor: pointer;font-size: 18px;" @click="_tailVisible(record)" /> -->
                            <a-popover title="跟踪记录" trigger="hover" placement="left">
                                <template slot="content">
                                    <div
                                            v-for="(item, index) in record.TrecordList"
                                            style="border-bottom: 1px solid #ddd;margin: 20px 0;padding: 10px;max-width:400px;"
                                    >
                                        <p style="font-size: 14px;">{{ item.RecordTit }}</p>
                                        <p
                                                style="font-size: 12px;color: #aaa;margin: 10px 0;text-align: right"
                                        >
                                            创建于 {{ item.CreateTime }} by {{ item.PersonName }}
                                        </p>
                                    </div>
                                </template>
                                <a-icon
                                        type="file-text"
                                        style="cursor: pointer;font-size: 18px;"
                                        @click="_tailVisible(record)"
                                />
                            </a-popover>
                        </div>
                    </a-table>
                </div>
                <div class="bottom-page" v-if="Schedulecount > 10">
                    <div class="bottom">
                        <a-input
                                style="width:200px;margin-right:10px;"
                                type="number"
                                :min="10"
                                addon-before="每页显示"
                                addon-after="个"
                                v-model="myPageSize"
                                @pressEnter="_pageChangeNum"
                        />
                        <a-pagination
                                showQuickJumper
                                :defaultCurrent="1"
                                :total="Schedulecount"
                                @change="_pageSchedule"
                                :page-size.sync="myPageSizeNum"
                        />
                    </div>
                </div>
            </div>
        </div>

        <a-modal v-model="relationVisible" title="联系方式" :footer="null">
            <p>登陆手机：{{ relationData.Phone }}</p>
            <p>父亲姓名：{{ relationData.Fname }}</p>
            <p>父亲电话：{{ relationData.Ftel }}</p>
            <p>母亲姓名：{{ relationData.Mname }}</p>
            <p>母亲电话：{{ relationData.Mtel }}</p>
            <p>家庭地址：{{ relationData.HomeAddr }}</p>
        </a-modal>

        <a-drawer
                title="跟踪记录"
                placement="right"
                width="500"
                :closable="true"
                :visible="tailVisible"
                @close="tailVisible = false"
        >
            <div
                    v-for="(item, index) in relationData.TrecordList"
                    style="border-bottom: 1px solid #ddd;margin: 20px 0;padding: 10px;"
            >
                <p style="font-size: 14px;">{{ item.RecordTit }}</p>
                <p style="font-size: 12px;color: #aaa;margin: 10px 0;text-align: right">
                    创建于 {{ item.CreateTime }} by {{ item.PersonName }}
                </p>
            </div>
        </a-drawer>
    </div>
    <div v-else class="operation-page">
        <div class="new-page">
            <div class="new-page-header">
                <div class="title">
                    {{ this.detailData ? '编辑活动信息' : '创建活动信息' }}
                </div>
                <div class="btn">
                    <a-button
                            type="primary"
                            style="width: 80px; margin-right: 10px"
                            @click="_save"
                    >确定</a-button
                    >
                    <a-button style="width: 80px" @click="_back">返回</a-button>
                </div>
            </div>
            <div class="container">基本信息</div>
            <div id="components-form-demo-advanced-search">
                <a-form class="ant-advanced-search-form">
                    <a-row :gutter="10">
                        <a-col :span="8">
                            <a-form-item :label="'活动名称'">
                                <a-input
                                        style="width: 250px;margin-left: 28px"
                                        placeholder="请输入"
                                        v-model="Name"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item :label="'城市中心'">
                                <div style=" display: flex;">
                                    <a-select
                                            style="width:100px"
                                            v-model="City"
                                            placeholder="城市"
                                            @change="_ChangeCity"
                                    >
                                        <a-select-option disabled selected hidden value=""
                                        >请选择</a-select-option
                                        >
                                        <a-select-option
                                                :value="item.CityName"
                                                v-for="(item, index) in CityName"
                                        >{{ item.CityName }}</a-select-option
                                        >
                                    </a-select>
                                    <a-select
                                            style="width:165px;margin-left:5px"
                                            v-model="SchoolName"
                                            @change="_changeSchool"
                                    >
                                        <a-select-option disabled selected hidden value=""
                                        >请选择</a-select-option
                                        >
                                        <a-select-option
                                                v-for="(item, index) in OrgName"
                                                :key="index"
                                        >{{ item.OrgName }}</a-select-option
                                        >
                                    </a-select>
                                </div>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item :label="'所属来源'">
                                <a-select
                                        style="width:110px"
                                        v-model="FSource"
                                        placeholder="类型"
                                        @change="_ChangeKind"
                                >
                                    <a-select-option disabled selected hidden value=""
                                    >请选择</a-select-option
                                    >
                                    <a-select-option
                                            v-for="(item, index) in sourceData"
                                            :key="index"
                                    >{{ item.FieldName }}</a-select-option
                                    >
                                </a-select>
                                <a-select
                                        style="width:170px;margin-left:5px"
                                        v-model="Source"
                                        placeholder="具体来源"
                                >
                                    <a-select-option disabled selected hidden value=""
                                    >请选择</a-select-option
                                    >
                                    <a-select-option
                                            :value="item.FieldName"
                                            v-for="(item, i) in secondList"
                                    >{{ item.FieldName }}</a-select-option
                                    >
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item :label="'活动预计费用'">
                                <a-input
                                        style="width: 250px;"
                                        placeholder="请输入"
                                        v-model="Money"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item :label="'活动类型'">
                                <a-select
                                        style="width:270px"
                                        v-model="Type"
                                        placeholder="请选择"
                                >
                                    <a-select-option disabled selected hidden value=""
                                    >请选择</a-select-option
                                    >
                                    <a-select-option
                                            :value="item.Name"
                                            v-for="(item, index) in activityData"
                                    >{{ item.Name }}</a-select-option
                                    >
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item :label="'负责人'">
                                <div class="row" style="position:relative">
                                    <a-input
                                            style="width: 285px;"
                                            placeholder="请输入"
                                            v-model="Person"
                                    />
                                    <div
                                            style="position:absolute;top:0;left:0;opacity: 0;z-index:100;width:100%;height: 100%"
                                    >
                                        <person
                                                v-bind="personObj"
                                                @getData="_getPerson"
                                                placeholder="请输入"
                                        ></person>
                                    </div>
                                </div>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item :label="'自动分配名单'">
                                <a-select
                                        style="width:270px"
                                        v-model="IsDistribution"
                                        placeholder="请选择"
                                >
                                    <a-select-option disabled selected hidden value=""
                                    >请选择</a-select-option
                                    >
                                    <a-select-option value="0">是</a-select-option>
                                    <a-select-option value="1">否</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8" v-if="IsDistribution ==0" >
                            <!--:defaultValue="schoolPersonId"-->

                            <a-form-item :label="'分配顾问'">
                                <a-select
                                        mode="multiple"
                                        labelInValue
                                        placeholder="请选择"
                                        v-model="schoolPersonIdObject"
                                        style="width: 270px"
                                        @change="changePerson"
                                >
                                    <a-select-option v-for="(item,i) in schoolPerson" :value="item.Id">{{item.Name}}</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item :label="'二维码状态'">
                                <a-select
                                        style="width:270px"
                                        v-model="CodeTime"
                                        placeholder="请选择"
                                >
                                    <a-select-option disabled selected hidden value=""
                                    >请选择
                                    </a-select-option
                                    >
                                    <a-select-option value="1">永久有效</a-select-option>
                                    <a-select-option value="0">临时有效</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                    </a-row>
                </a-form>
                <p class="info-container" style="padding:0 10px 15px 10px">
                    <span class="info-title">备注</span>
                    <a-textarea
                            style="margin-top:5px;height: 100px;resize: none"
                            v-model="Intro"
                            placeholder="请输入"
                    />
                </p>
            </div>
        </div>
        <div class="related-table">
            <div class="header">
                <div class="title">关联渠道</div>
                <div class="create-btn">
                    <a-button type="primary" @click="_addChannel">添加</a-button>
                </div>
            </div>
            <div class="container">
                <!-- 关联渠道列表 -->
                <a-table
                        bordered
                        :columns="channelTable"
                        :dataSource="channel"
                        :pagination="false"
                >
                    <!-- <div slot="real_fee" slot-scope="text, record">
                                  <editable-cell :text="text" @change="onCellChange(record.Id, 'real_fee', $event)" />
                              </div> -->
                    <div slot="Operation" slot-scope="text, record, index">
                        <a-popconfirm
                                title="您确定要删除吗?"
                                @confirm="_delConTable(index)"
                                okText="确定"
                                cancelText="取消"
                        >
                            <i
                                    class="iconfont icon-shanchu- icon-btn"
                                    style="font-size: 20px;"
                            ></i>
                        </a-popconfirm>
                    </div>
                </a-table>
            </div>
        </div>
        <a-drawer
                title="添加关联渠道"
                placement="right"
                width="1000"
                :closable="false"
                @close="_channelTabClose(1)"
                :visible="channelTab"
        >
            <div>
                <a-tabs>
                    <a-tab-pane key="1" tab="合作渠道">
                        <a-table
                                bordered
                                :columns="AssociatedChannel"
                                :dataSource="ACData"
                                :showAlertInfo="true"
                                :pagination="false"
                                :rowSelection="{
                selectedRowKeys: PersonSelectedRowKeys,
                onChange: PersonOnChange
              }"
                                style="margin-bottom:40px"
                        >
                        </a-table>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="兼职渠道">
                        <a-table
                                bordered
                                :columns="partTimeChannel"
                                :dataSource="partTimeData"
                                :showAlertInfo="true"
                                :pagination="false"
                                :rowSelection="{
                selectedRowKeys: partKeys,
                onChange: partTimeOnChange
              }"
                                style="margin-bottom:40px"
                        >
                        </a-table>
                    </a-tab-pane>
                </a-tabs>
            </div>
            <div class="alert-footer">
                <!-- <a-button type="primary" style="margin-right: 25px" @click="_judgeData">确定</a-button> -->
                <a-button
                        type="primary"
                        style="margin-right: 25px"
                        @click="_channelSave"
                >确定</a-button
                >
                <a-button @click.stop="_channelTabClose">取消</a-button>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    import myAxios from 'axios'
    //axios请求
    const http = myAxios.create({
        baseURL: ' https://sapi.sikegroup.com',
        withCredentials: false,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        transformRequest: [
            function (data) {
                let newData = ''
                for (let k in data) {
                    if (data.hasOwnProperty(k) === true) {
                        newData +=
                            encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) + '&'
                    }
                }
                return newData
            }
        ]
    })
    import person from 'components/SelectPersonnel/SelectPersonnel' //选人
    import QRCode from 'qrcodejs2'
    const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
    export default {
        name: 'activityWork',
        components: {
            person,
            QRCode
        },
        data () {
            return {
                personObj: {
                    rank: 1, //职级 0为不选，1为选
                    nums: 1, //选的数量
                    department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: '', //常用联系人 不要常用联系人传空''
                    selectArr: [] //要传入的数据
                },
                setItemData: '',
                relationVisible: false,
                tailVisible: false,
                relationData: '',
                create_time: '',
                count: 0,
                TotalListShow: false,
                activity_name: '',
                activity_person: '',
                searcheCity: '',
                sourceType: '',
                Type: '',
                RealFee: '',
                itemid: '',
                Person: '',
                SchoolName: '',
                Intro: '',
                Name: '',
                file: '',
                IsMine: 0,
                orderType: '',
                headers: {
                    authorization: 'authorization-text'
                },
                activityData: [],
                selectedType: '',

                timeCombination: '',
                customerName: '',
                personName: '',
                QrcodeFrom: '',
                Page: 1,
                Parttime: '',
                FSource: '',
                Source: '',
                nameListNote: '',
                nameListNoteR: '',
                selectConsultant: '',
                selectConsultantR: '',
                nameSelectedCenter: '',
                nameSelectedCenterR: '',
                Saasuser: '',
                SaasUserId: '',
                SaasUserName: '',
                visible: false,
                Rightvisible: false,
                channelTab: false,
                distributionTab: false,
                channelId: '',
                Money: '',
                SchoolId: '',
                PersonId: '',
                myPageSize: 10,
                myPageSizeRight: 10,
                myPageSizeNum: 10,
                myPageSizeNumRight: 10,
                Schedulecount: 0,
                SchedulecountRight: 0,
                City: '',
                Id: '',
                Channel: '',
                ChannelId: '',
                centerList: '',
                personList: '',
                StartDate: '',
                EndDate: '',
                FSourceId: '',
                ParttimeId: '',
                userlist: '',
                userlistR: '',
                orderType: '',
                CenterName: [],
                consultantArr: [],
                PersonSelectedRowKeys: [],
                NameListKeys: [],
                NameList: [],
                NameListKeysRight: [],
                NameListRight: [],
                PersonSelectedRow: [],
                partKeys: [],
                partTimeSelectedRow: [],
                channelAll: [],
                secondList: [],
                detailListData: [],
                TotalSelectedData: [],
                tableTotalData: [],
                StudentData: [],
                track_status_list: [],
                status_list: [],
                orderTypeArr: ['会员合同', '线上课包', '线上练习册'],
                ACData: [],
                partTimeData: [],
                AssociatedChannel: [],
                nameArrCenter: [],
                channelType: '',
                listState: '',
                trackListState: '',
                trackListStateRight: '',
                centerListRight: '',
                personListRight: '',
                listStateRight: '',
                customerNameRight: '',
                personNameRight: '',
                QrcodeFromRight: '',
                StartTime: '',
                ActivityId: '',
                EndTime: '',
                detailData: '',
                searcheStores: '',
                channel: [],
                viewData: false,
                isNew: false,
                selectCity: undefined,
                selectStores: undefined,
                workBookVisible: true,
                IsDistribution:'0',
                DataStartDate: '',
                DataEndDate: '',
                sourceData: [],
                CityName: [],
                OrgName: [],
                tableData: [],
                StartDateStu: '',
                EndDateStu: '',
                DetailParttimeId: '',
                DetailChannelId: '',
                tableColumns: [
                    {
                        title: '序号',
                        dataIndex: 'sNumber',
                        width: 80,
                        align: 'center',
                        key: 'sNumber'
                    },
                    {
                        title: '城市',
                        dataIndex: 'CityName',
                        width: 130,
                        align: 'center',
                        key: 'CityName'
                    },
                    {
                        title: '中心',
                        dataIndex: 'SchoolName',
                        width: 250,
                        align: 'center',
                        key: 'SchoolName'
                    },
                    {
                        title: '活动名称',
                        dataIndex: 'Name',
                        width: 500,
                        align: 'center',
                        key: 'Name',
                        scopedSlots: {
                            customRender: 'ActivityName'
                        }
                    },
                    {
                        title: '来源',
                        dataIndex: 'Source',
                        width: 180,
                        align: 'center',
                        key: 'Source'
                    },
                    {
                        title: '负责人',
                        dataIndex: 'Person',
                        key: 'Person',
                        width: 150,
                        align: 'center'
                    },
                    {
                        title: '创建人',
                        dataIndex: 'CreateUser',
                        key: 'CreateUser',
                        width: 200,
                        align: 'center'
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'CreateTime',
                        key: 'CreateTime',
                        width: 220,
                        align: 'center'
                    },
                    {
                        title: '操作',
                        dataIndex: 'Operation',
                        key: 'Operation',
                        width: 200,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'Operation'
                        }
                    }
                ],
                channelTable: [
                    {
                        title: '序号',
                        dataIndex: 'sNumber',
                        key: 'sNumber',
                        width: 50,
                        align: 'center'
                    },
                    {
                        title: '渠道名称',
                        dataIndex: 'Name',
                        key: 'Name',
                        width: 200,
                        align: 'center'
                    },
                    {
                        title: '等级',
                        dataIndex: 'Level',
                        key: 'Level',
                        width: 100,
                        align: 'center'
                    },
                    {
                        title: '预估基本费用',
                        dataIndex: 'fee',
                        key: 'fee',
                        width: 200,
                        align: 'center'
                    },
                    {
                        title: '实际支出',
                        dataIndex: 'real_fee',
                        key: 'real_fee',
                        width: 200,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'name'
                        }
                    },
                    {
                        title: '操作',
                        dataIndex: 'Operation',
                        key: 'Operation',
                        width: 150,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'Operation'
                        }
                    }
                ],
                tableTotalColumns: [
                    {
                        title: '序号',
                        dataIndex: 'sNumber',
                        key: 'sNumber',
                        width: 50,
                        align: 'center'
                    },
                    {
                        title: '有效名单',
                        dataIndex: 'yx',
                        key: 'yx',
                        width: 100,
                        align: 'center',
                        scopedSlots: { customRender: 'yx' }
                    },
                    {
                        title: '重复',
                        dataIndex: 'exist',
                        key: 'exist',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'exist'
                        }
                    },
                    {
                        title: '已联系',
                        dataIndex: 'contact',
                        key: 'contact',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'contact'
                        }
                    },
                    {
                        title: '已跟踪',
                        dataIndex: 'tracked',
                        key: 'tracked',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'tracked'
                        }
                    },
                    {
                        title: '到访',
                        dataIndex: 'visit',
                        key: 'visit',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'visit'
                        }
                    },
                    {
                        title: '体验',
                        dataIndex: 'ty',
                        key: 'ty',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'ty'
                        }
                    },
                    {
                        title: '成交',
                        dataIndex: 'deal',
                        key: 'deal',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'deal'
                        }
                    },
                    {
                        title: '订单数量',
                        dataIndex: 'order_num',
                        key: 'order_num',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'order_num'
                        }
                    },
                    {
                        title: '订单金额',
                        dataIndex: 'order_fee',
                        key: 'order_fee',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'order_fee'
                        }
                    },
                    {
                        title: '预估费用',
                        dataIndex: 'fee',
                        key: 'fee',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'fee'
                        }
                    }
                ],
                statisticalColumns: [
                    {
                        title: '序号',
                        dataIndex: 'sNumber',
                        key: 'sNumber',
                        width: 70,
                        align: 'center'
                    },
                    {
                        title: '渠道',
                        dataIndex: 'Name',
                        key: 'Name',
                        width: 100,
                        align: 'center'
                    },
                    // {
                    //  title: "所有名单",
                    //  dataIndex: "all",
                    //  width: 100,
                    //  align: "center",
                    // },
                    {
                        title: '有效名单',
                        dataIndex: 'parttime_yx',
                        key: 'parttime_yx',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'parttime_yx'
                        }
                    },
                    {
                        title: '重复',
                        dataIndex: 'parttime_exist',
                        key: 'parttime_exist',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'parttime_exist'
                        }
                    },
                    {
                        title: '已联系',
                        dataIndex: 'parttime_contact',
                        key: 'parttime_contact',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'parttime_contact'
                        }
                    },
                    {
                        title: '到访',
                        dataIndex: 'parttime_visit',
                        key: 'parttime_visit',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'parttime_visit'
                        }
                    },
                    {
                        title: '体验',
                        dataIndex: 'parttime_ty',
                        key: 'parttime_ty',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'parttime_ty'
                        }
                    },
                    {
                        title: '成交',
                        dataIndex: 'parttime_deal',
                        key: 'parttime_deal',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'parttime_deal'
                        }
                    },
                    {
                        title: '订单数量',
                        dataIndex: 'parttime_order_num',
                        key: 'parttime_order_num',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'parttime_order_num'
                        }
                    },
                    {
                        title: '订单金额',
                        dataIndex: 'parttime_order_fee',
                        key: 'parttime_order_fee',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'parttime_order_fee'
                        }
                    },
                    {
                        title: '预估费用',
                        dataIndex: 'fee',
                        key: 'fee',
                        width: 100,
                        align: 'center'
                    },
                    {
                        title: '操作',
                        dataIndex: 'Operation',
                        key: 'Operation',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'Operation'
                        }
                    }
                ],
                ScheduleTable: [
                    {
                        title: '序号',
                        dataIndex: 'sNumber',
                        key: 'sNumber',
                        width: 50,
                        align: 'center'
                    },
                    {
                        title: '客户姓名',
                        dataIndex: 'StudentName',
                        key: 'StudentName',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'studentname'
                        }
                    },
                    {
                        title: '电话',
                        dataIndex: 'UserName',
                        key: 'UserName',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'username'
                        }
                    },
                    {
                        title: '所属中心',
                        dataIndex: 'SchoolName',
                        key: 'SchoolName',
                        width: 100,
                        align: 'center'
                    },
                    {
                        title: '顾问姓名',
                        dataIndex: 'Person',
                        key: 'Person',
                        width: 100,
                        align: 'center'
                    },
                    {
                        title: '订单数量/订单金额',
                        dataIndex: 'OrderInfo',
                        key: 'OrderInfo',
                        width: 100,
                        align: 'center'
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'CreateTime',
                        key: 'CreateTime',
                        width: 100,
                        align: 'center'
                    },
                    {
                        title: '二维码来源',
                        dataIndex: 'QrcodeUrl',
                        key: 'QrcodeUrl',
                        width: 100,
                        align: 'center'
                    },
                    {
                        title: '状态',
                        dataIndex: 'Status',
                        key: 'Status',
                        width: 100,
                        align: 'center'
                    },
                    {
                        title: '名单状态',
                        dataIndex: 'TrackStatus',
                        key: 'TrackStatus',
                        width: 100,
                        align: 'center'
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        key: 'operation',
                        width: 100,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'operation'
                        }
                    }
                ],
                AssociatedChannel: [
                    {
                        title: '序号',
                        dataIndex: 'sNumber',
                        key: 'sNumber',
                        width: 30,
                        align: 'center'
                    },
                    {
                        title: '渠道',
                        dataIndex: 'Name',
                        key: 'Name',
                        width: 100,
                        align: 'center'
                    },
                    {
                        title: '预计费用',
                        dataIndex: 'ExpectFee',
                        key: 'ExpectFee',
                        width: 100,
                        align: 'center'
                    }
                ],
                partTimeChannel: [
                    {
                        title: '序号',
                        dataIndex: 'sNumber',
                        key: 'sNumber',
                        width: 30,
                        align: 'center'
                    },
                    {
                        title: '渠道',
                        dataIndex: 'Name',
                        key: 'Name',
                        width: 100,
                        align: 'center'
                    },
                    {
                        title: '等级',
                        dataIndex: 'Level',
                        key: 'Level',
                        width: 100,
                        align: 'center'
                    },
                    {
                        title: '基本工资',
                        dataIndex: 'BaseSalary',
                        key: 'BaseSalary',
                        width: 100,
                        align: 'center'
                    }
                ],
                schoolPerson:[],//中心顾问
                CodeTime:"0",
                schoolPersonIdObject:[],
            }
        },
        created () {
            this._getInfo()
        },
        mounted () {
            this.$axios.post(8000002, {}, res => {
                if (res.data.code == 1) {
                    this.CityName = res.data.data
                }
            })
            this.Saasuser = JSON.parse(window.sessionStorage.getItem('userInfo'))
            console.log(this.Saasuser)
            this.SaasUserId = this.Saasuser.uid
            this.SaasUserName = this.Saasuser.name
        },
        methods: {
            _addQrCode (url, dom, w) {
                let self = this
                let qrcode = new QRCode(dom, {
                    text: url,
                    width: 300,
                    height: 300,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
            },
            _getInfo () {
                //初始化列表,并在新建、编辑、删除时调用
                this.$axios.post(8000005, {}, res => {
                    if (res.data.code == 1) {
                        this.tableData = res.data.data
                        this.count = res.data.count
                        this.tableData.map((res, index) => {
                            res.sNumber = index + 1
                        })
                    }
                })
            },
            resetBtn () {
                ;(this.create_time = ''), (this.activity_name = '')
                this.activity_person = ''
                this.searcheCity = ''
                this.searcheStores = ''
                this.CenterName = ''
            },
            _tailVisible (data) {
                this.relationData = data
                this.tailVisible = true
                console.log(data)
            },
            _relationVisible (data) {
                this.relationData = data
                this.relationVisible = true
                console.log(data)
            },
            onCellChange (key, real_fee, value) {
                const dataSource = [this.channel]
                const target = dataSource.find(item => item.key === key)
                if (target) {
                    target[real_fee] = value
                    this.dataSource = dataSource
                }
            },
            _showNameTotal (Type) {
                this.TotalListShow = true
                this.selectedType = Type
                this.DetailParttimeId = ''
                this._scheduleTableRight()
            },
            _showParttimeDetail(Type,ParttimeId,DetailType){
                if (DetailType === 'parttime') {
                    this.DetailChannelId = ''
                    this.DetailParttimeId = ParttimeId
                } else {
                    this.DetailParttimeId = ''
                    this.DetailChannelId = ParttimeId
                }
                this.TotalListShow = true
                this.selectedType = Type
                // this.DetailParttimeId = ParttimeId
                this._scheduleTableRight()
            },
            _copy (data) {
                this.$axios.get(
                    8000030,
                    {
                        Id: data.Id,
                        UserId: userInfo.uid,
                        UserName: userInfo.name
                    },
                    res => {
                        if (res.data.code == 1) {
                            // let item = this.ObjectClone(data);
                            // item.Id = res.data.data;
                            // item.sNumber = 0;
                            // this.tableData.unshift(item);
                            this._getInfo()
                        }
                    }
                )
            },
            _setItem (i) {
                console.log(i)
                this.isNew = true
                this.itemid = i
                this.$axios.get(
                    8000015,
                    {
                        Id: i.Id
                    },
                    res => {
                        if (res.data.code == 1) {
                            // this.setData = res.data.data;
                            // this.setLevel = res.data.level;
                            this.Id = res.data.info.Id
                            if (i.Id) {
                                this.detailData = res.data.info
                            }
                            this.Name = res.data.info.Name
                            this.SchoolId = res.data.info.SchoolId
                            this.SchoolName = res.data.info.SchoolName
                            this.Type = res.data.info.Type
                            this.Person = res.data.info.Person
                            this.City = res.data.info.CityName
                            this.FSource = res.data.info.FSource
                            this.FSourceId = res.data.info.FSourceId
                            this.RealFee = res.data.info.RealFee
                            this.Money = res.data.info.Money
                            this.Intro = res.data.info.Intro
                            this.Source = res.data.info.Source
                            this.PersonId = res.data.info.PersonId
                            this.Channel = res.data.info.Channel
                            this.ChannelId = res.data.info.ChannelId
                            this.Parttime = res.data.info.Parttime
                            this.ParttimeId = res.data.info.ParttimeId
                            this.IsDistribution = res.data.info.IsDistribution+'';
                            this.CodeTime = res.data.info.CodeTime+'';
                            if(res.data.info.SchoolId){
                                this.get_scool_person(2);
                            }
                            if(res.data.info.schoolPersonIdObject){
                                for (let i=0;i<res.data.info.schoolPersonIdObject.length;i++){
                                    let arr ={};
                                    arr.key = res.data.info.schoolPersonIdObject[i].PersonId;
                                    arr.label = res.data.info.schoolPersonIdObject[i].Person;
                                    this.schoolPersonIdObject.push(arr);
                                }
                            }
                            //小王子生成二维码
                            if (res.data.info.QrUrl) {
                                setTimeout(() => {
                                    this._addQrCode(
                                        this.detailData.QrUrl,
                                        document.getElementById('activity-qr-code'),
                                        120
                                    )
                                })
                            }
                            // this.channel.map((res, index) => {
                            //  res.sNumber = index + 1;
                            // });
                            // this.channelAll = res.data.channel;
                            // this.channelAll.map((res, index) => {
                            //  res.sNumber = index + 1;
                            //  // if(res.Type = "parttime") {
                            //  //  res.Operation = 1;
                            //  // }
                            // });
                            this.track_status_list = res.data.track_status_list
                            this.status_list = res.data.status_list
                            this._ChangeCity(this.City)
                        }
                    }
                )
                // _ChangeCity(this.City);
                this.$axios.post(8000014, {}, res => {
                    if (res.data.code == 1) {
                        this.sourceData = res.data.data
                        this.sourceData.map(r2 => {})
                    }
                })

                this.$axios.post(8000028, {}, res => {
                    if (res.data.code == 1) {
                        this.activityData = res.data.data
                    }
                })

                this.channel = []
                this.channelAll = []
                this.StudentData = []
                if (i.Id) {
                    this.$message.loading('正在加载渠道统计数据...', 0)
                    this.$axios.get(
                        8000036,
                        {
                            ActivityId: i.Id
                        },
                        res => {
                            if (res.data.code == 1) {
                                this.channel = res.data.data
                                this.channel.map((res, index) => {
                                    res.sNumber = index + 1
                                })
                                this.channelAll = res.data.data
                                this.channelAll.map((res, index) => {
                                    res.sNumber = index + 1
                                })
                                this.StudentData = [res.data.total_count]
                                let arr = []
                                this.StudentData.map((res, index) => {
                                    res.sNumber = index + 1
                                    arr.push(
                                        Object.assign(res, {
                                            Select: [
                                                'all',
                                                'yx',
                                                'exist',
                                                'contact',
                                                'visit',
                                                'ty',
                                                'deal',
                                                'order_num',
                                                'order_fee',
                                                'fee',
                                                'tracked'
                                            ]
                                        })
                                    )
                                })
                                this.$message.destroy()
                            } else {
                                this.$message.destroy()
                                this.$message.error(res.data.msg)
                            }
                        }
                    )
                }
            },
            _save () {
                let data = {
                    Name: this.Name,
                    CityName: this.City,
                    SchoolId: this.SchoolId || '',
                    SchoolName: this.SchoolName || '',
                    Money: this.Money,
                    PersonId: this.PersonId || '',
                    Person: this.Person || '',
                    Intro: this.Intro || '',
                    Type: this.Type || '',
                    FSource: this.FSourceId,
                    Source: this.Source || '',
                    ChannelId: this.ChannelId || '',
                    ParttimeId: this.ParttimeId || '' ,
                    IsDistribution:this.IsDistribution,
                    CodeTime:this.CodeTime,
                    schoolPersonIdObject:this.schoolPersonIdObject.length>0?JSON.stringify(this.schoolPersonIdObject):'',

                }
                if (this.detailData) {
                    data.Id = this.detailData.Id
                    this.$axios.post(8000016, data, res => {
                        if (res.data.code == 1) {
                            this.$message.success('修改成功')
                            this._getInfo()
                            this._back()
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    })
                } else {
                    this.$axios.post(8000016, data, res => {
                        if (res.data.code == 1) {
                            this.$message.success('添加成功')
                            this._getInfo()
                            this._back()
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    })
                }
                // this._back();
            },
            _closeTotalShow () {
                this.TotalListShow = false
                this.myPageSizeNumRight = 10
                this.myPageSizeRight = 10
                this.NameListKeysRight = []
                this.NameListRight = []
                this._customerResetBtnRight()
            },
            _SelectedCenter () {
                this.$axios.post(
                    8000025,
                    {
                        UserId: this.SaasUserId,
                        SchoolId: this.nameSelectedCenter
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.consultantArr = res.data.data
                        }
                    }
                )
            },
            _SelectedCenterR () {
                console.log(this.nameSelectedCenterR)
                this.$axios.post(
                    8000025,
                    {
                        UserId: this.SaasUserId,
                        SchoolId: this.nameSelectedCenterR
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.consultantArr = res.data.data
                        }
                    }
                )
            },
            showModal () {
                if (this.detailListData.length == 0) {
                    this.$message.error('亲，你没有可以选择的学生，不能使用该功能')
                } else if (this.NameListKeys.length == 0) {
                    this.$message.warning('亲，你还没有选择学生，请先选择左边的学生')
                } else {
                    this.visible = true
                    this.$axios.get(
                        8000024,
                        {
                            UserId: this.SaasUserId
                        },
                        res => {
                            if (res.data.code == 1) {
                                this.nameArrCenter = res.data.data
                            }
                        }
                    )
                }
            },
            showModalRight () {
                if (this.TotalSelectedData.length == 0) {
                    this.$message.error('亲，你没有可以选择的学生，不能使用该功能')
                } else if (this.NameListKeysRight.length == 0) {
                    this.$message.warning('亲，你还没有选择学生，请先选择学生')
                } else {
                    this.Rightvisible = true
                    this.$axios.get(
                        8000024,
                        {
                            UserId: this.SaasUserId
                        },
                        res => {
                            if (res.data.code == 1) {
                                this.nameArrCenter = res.data.data
                            }
                        }
                    )
                }
            },
            _PageChange (pageNumber) {
                this.serachBtn(pageNumber)
            },
            _pageChangeNum () {
                this.$message.loading('数据加载中，请耐心等候')
                this.myPageSizeNum = Number(this.myPageSize)
                this._scheduleTable()
            },
            _pageChangeNumRight () {
                this.$message.loading('数据加载中，请耐心等候')
                this.myPageSizeNumRight = Number(this.myPageSizeRight)
                this._scheduleTableRight()
            },
            _pageSchedule (pageNumber) {
                this._scheduleTable(pageNumber)
            },
            _pageScheduleRight (pageNumber) {
                this._scheduleTableRight(pageNumber)
            },
            serachBtn (page) {
                this.$axios.post(
                    8000005,
                    {
                        CityName: this.searcheCity,
                        Schools: this.searcheStores,
                        StartTime: this.StartDate,
                        EndTime: this.EndDate,
                        Name: this.activity_name,
                        Person: this.activity_person,
                        Page: page,
                        PageSize: 10
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.tableData = res.data.data
                            this.count = res.data.count
                            this.tableData.map((res, index) => {
                                if (page) {
                                    res.sNumber = (page - 1) * 10 + index + 1
                                } else {
                                    res.sNumber = index + 1
                                }
                            })
                        } else {
                            this.tableData = []
                            this.count = 0
                        }
                    }
                )
            },
            _skipSkim (id, name) {
                let data = {
                    type: 'student',
                    name: name,
                    id: id
                }
                window.parent.postMessage(data, '*')
            },
            _changeOrg (v) {
                let arr = this.OrgName[v]
                this.SchoolId = arr.Id
                this.SchoolName = arr.OrgName
            },
            _getPerson (Person) {
                this.Person = Person[0].title
                this.PersonId = Person[0].id
            },
            // _search(id) {
            //  this.serachBtn(1);
            // },
            NameListChange (selectedRowKeys, selectedRows) {
                this.NameListKeys = selectedRowKeys
                this.NameList = selectedRows
                console.log(this.NameList)
            },
            NameListChangeRight (selectedRowKeys, selectedRows) {
                this.NameListKeysRight = selectedRowKeys
                this.NameListRight = selectedRows
            },
            handleOk () {
                let length = this.NameList.length
                if (length != 0) {
                    this.userlist = this.NameList[0].Id
                    for (let i = 1; i < length; i++) {
                        let data = this.NameList[i].Id
                        this.userlist += ',' + data
                    }
                }
                this.$axios.post(
                    8000026,
                    {
                        schoolid_new: this.nameSelectedCenter,
                        newperson: this.selectConsultant,
                        remark: this.nameListNote,
                        userlist: this.userlist
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.$message.success(res.data.msg)
                            this.visible = false
                            this.NameListKeys = []
                            this._scheduleTable()
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    }
                )
            },
            handleOkRight () {
                let userList = ''
                let self = this
                if (this.NameListRight.length != 0) {
                    for (let i = 0; i < this.NameListRight.length; i++) {
                        userList += self.NameListRight[i].Id + ','
                    }
                }
                this.$axios.post(
                    8000026,
                    {
                        schoolid_new: this.nameSelectedCenterR,
                        newperson: this.selectConsultantR,
                        remark: this.nameListNoteR,
                        userlist: userList.substring(0, userList.length - 1)
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.$message.success(res.data.msg)
                            this.Rightvisible = false
                            this.NameListKeysRight = []
                            this._scheduleTableRight()
                            // self._setItem(self.setItemData);
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    }
                )
            },
            handleCanle () {
                this.nameSelectedCenter = ''
                this.selectConsultant = ''
                this.nameListNote = ''
            },
            handleCanleRight () {
                this.nameSelectedCenterR = ''
                this.selectConsultantR = ''
                this.nameListNoteR = ''
                //this.Rightvisible = false;
            },
            _viewData (index) {
                this.viewData = true
                this.isNew = true
                this.ActivityId = index.Id
                this.setItemData = index
                this._setItem(index)
                this._scheduleTable()
            },
            _scheduleTable (page) {
                this.$axios.get(
                    8000020,
                    {
                        ActivityId: this.ActivityId,
                        SchoolId: this.centerList,
                        PersonId: this.personName,
                        Status: this.listState,
                        TrackStatus: this.trackListState,
                        StudentName: this.customerName,
                        QrcodeFrom: this.QrcodeFrom,
                        Page: page,
                        PageSize: this.myPageSize,
                        StartTime: this.StartDateStu,
                        EndTime: this.EndDateStu
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.detailListData = res.data.data
                            this.Schedulecount = res.data.count
                            let array = []
                            this.detailListData.map((res, index) => {
                                if (page) {
                                    res.sNumber = (page - 1) * this.myPageSize + index + 1
                                } else {
                                    res.sNumber = index + 1
                                }
                                array.push(
                                    Object.assign(res, {
                                        key: res.sNumber
                                    })
                                )
                            })

                            this.detailListData = array
                            console.log(this.detailListData)
                        } else {
                            this.detailListData = []
                            this.Schedulecount = 0
                        }
                    }
                )
            },
            // 右侧抽屉
            _scheduleTableRight (page) {
                this.TotalSelectedData = []
                this.$message.loading('正在查询...', 0)
                this.$axios.get(
                    8000020,
                    {
                        ActivityId: this.ActivityId,
                        SchoolId: this.centerListRight,
                        PersonId: this.personNameRight,
                        Status: this.listStateRight,
                        TrackStatus: this.trackListStateRight,
                        StudentName: this.customerNameRight,
                        QrcodeFrom: this.QrcodeFromRight,
                        Page: page,
                        PageSize: this.myPageSizeRight,
                        Type: this.selectedType,
                        StartTime: this.StartDateStu,
                        EndTime: this.EndDateStu,
                        ParttimeId: this.DetailParttimeId,
                        ChannelId: this.DetailChannelId,
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.TotalSelectedData = res.data.data
                            this.SchedulecountRight = res.data.count
                            let array = []
                            this.TotalSelectedData.map((res, index) => {
                                if (page) {
                                    res.sNumber = (page - 1) * this.myPageSizeRight + index + 1
                                } else {
                                    res.sNumber = index + 1
                                }
                                array.push(
                                    Object.assign(res, {
                                        key: res.sNumber
                                    })
                                )
                            })
                            this.TotalSelectedData = array
                            this.$message.destroy()
                        } else {
                            this.TotalSelectedData = []
                            this.SchedulecountRight = 0
                            this.$message.destroy()
                            this.$message.error(res.data.msg, 1)
                        }
                    }
                )
            },
            _addChannel () {
                this.channelTab = true
                this.PersonSelectedRow = []
                this.partTimeSelectedRow = []
                this.PersonSelectedRowKeys = []
                this.partKeys = []
                this.channel.map(result => {
                    if (result.Type == 'parttime') {
                        this.partTimeSelectedRow.push(result)
                    } else {
                        this.PersonSelectedRow.push(result)
                    }
                })
                this.$message.loading('加载中...', 0)
                this.$axios.post(
                    8000022,
                    {
                        City: this.City,
                        SchoolId: this.SchoolId || ''
                    },
                    res => {
                        if (res.data.code == 1) {
                            //渠道
                            this.ACData = res.data.data.channel
                            this.ACData.map((res, index) => {
                                res.sNumber = index + 1
                                this.PersonSelectedRow.map(r2 => {
                                    if (res.Id == r2.Id) {
                                        this.PersonSelectedRowKeys.push(index)
                                    }
                                })
                            })
                            console.log(res)
                            console.log(this.PersonSelectedRowKeys)

                            //人员
                            this.partTimeData = res.data.data.parttime
                            this.partTimeData.map((res, index) => {
                                res.sNumber = index + 1
                                this.partTimeSelectedRow.map(r2 => {
                                    if (res.Id == r2.Id) {
                                        this.partKeys.push(index)
                                    }
                                })
                            })
                            console.log(this.partKeys)
                            this.$message.destroy()
                        } else {
                            this.ACData = []
                            this.partTimeData = []
                            this.$message.error(res.data.msg)
                        }
                    }
                )
            },
            _channelTabClose (i) {
                if (i) {
                    this.ChannelId = ''
                    this.ParttimeId = ''
                }
                this.channelTab = false
                this.PersonSelectedRow = []
                this.PersonSelectedRowKeys = []
                this.partTimeSelectedRow = []
                this.partKeys = []
                this.channelTab = false
            },
            _ChangeCity (value) {
                // this.SchoolName = "";
                this.$axios.get(
                    8000006,
                    {
                        CityName: value
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.OrgName = res.data.data
                        }
                    }
                )
            },
            _ChangeCityInfo (value) {
                this.searcheStores = ''
                // this.SchoolName = "";
                this.$axios.get(
                    8000006,
                    {
                        CityName: value
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.CenterName = res.data.data
                        }
                    }
                )
            },
            _ChangeKind (i) {
                let arr = this.sourceData[i].children
                this.FSourceId = this.sourceData[i].Id
                this.secondList = arr
                this.Source = ''
                // this.SchoolId = arr.Id;
                // this.SchoolName = arr.OrgName;
            },
            _getId (view) {
                console.log(view.Id)
                this.channelId = view.Id
            },
            handleChange (e, id) {
                //文件上传
                this.file = e.target.files
                this.upFile(this.file, id)
            },
            async upFile (ExcelFile, id) {
                this.$message.loading('上传中...', 0)
                let xhr = new XMLHttpRequest()
                let formData = new FormData()
                let self = this
                //'https://up-z2.qbox.me'
                xhr.open('POST', 'https://sapi.sikegroup.com/market/v1/excel_import', true)
                formData.append(
                    'access_token',
                    window.sessionStorage.getItem('access_token')
                )
                formData.append('SaasUserId', this.SaasUserId)
                formData.append('SaasUserName', this.SaasUserName)
                formData.append('ActivityId', this.Id)
                formData.append('ChannelId', id)
                formData.append('File', ExcelFile[0])
                xhr.onreadystatechange = function (response) {
                    if (
                        xhr.readyState == 4 &&
                        xhr.status == 200 &&
                        xhr.responseText != ''
                    ) {
                        let blkRet = JSON.parse(xhr.responseText)
                        self.$message.destroy()
                        if (blkRet.code == 1) {
                            self._setItem(self.setItemData)
                            self._scheduleTable()
                            self.$message.success(blkRet.msg)
                            document.getElementsByClassName('input_file')[0].value = ''
                        } else {
                            self.$message.error(blkRet.msg)
                        }
                    }
                }
                xhr.send(formData)
            },
            _showNewPage () {
                this.isNew = true
            },
            _back () {
                this.isNew = false
                this.Name = ''
                this.SchoolName = ''
                this.Person = ''
                this.Intro = ''
                this.City = ''
                this.FSource = ''
                this.Source = ''
                this.Type = ''
                this.Money = ''
                this.Channel = ''
                this.Parttime = ''
                this.channel = []
                this.OrgName = []
                this.secondList = []
                this.detailData = ''
                this.schoolPersonIdObject =[];
                this.schoolPerson =[];
                this.CodeTime ="0";
                this.IsDistribution ="0";
            },
            ChangeSearchTime (time, timeString) {
                this.StartDate = timeString[0]
                this.EndDate = timeString[1]
            },
            ChangeStuSearchTime (time, timeString) {
                this.StartDateStu = timeString[0]
                this.EndDateStu = timeString[1]
            },
            DataSearchTime (time, timeString) {
                this.DataStartDate = timeString[0]
                this.DataEndDate = timeString[1]
            },
            _delTableRow (index) {
                this.$axios.post(
                    8000017,
                    {
                        Id: index.Id
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.$message.success('删除成功')
                            // this.tableData.splice(index, 1);
                            this._getInfo()
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    }
                )
            },
            _delConTable (index) {
                //ParttimeId
                //ChannelId
                if (this.channel[index].Type == 'parttime') {
                    let data = this.ParttimeId.split(this.channel[index].Id + ',')
                    this.ParttimeId = data.length > 1 ? data.join('') : ''
                } else {
                    let data = this.ChannelId.split(this.channel[index].Id + ',')
                    this.ChannelId = data.length > 1 ? data.join('') : ''
                }
                this.channel.splice(index, 1)
            },
            _customerResetBtn () {
                ;(this.trackListState = ''),
                    (this.centerList = ''),
                    (this.personName = ''),
                    (this.listState = ''),
                    (this.customerName = ''),
                    (this.QrcodeFrom = ''),
                    (this.NameListKeys = []),
                    (this.NameList = []),
                    (this.orderType = '')
            },
            _customerResetBtnRight () {
                ;(this.trackListStateRight = ''),
                    (this.centerListRight = ''),
                    (this.personNameRight = ''),
                    (this.listStateRight = ''),
                    (this.customerNameRight = ''),
                    (this.QrcodeFromRight = '')
            },
            get_scool_person(val){
                let self = this;
                if(val==1){
                    this.schoolPersonIdObject = [];
                }
                this.$axios.get(12934,{SchoolId:self.SchoolId},res=>{
                    if(res.data.code==1){

                        self.schoolPerson = res.data.data;
                    }else{
                        self.schoolPerson =[];
                    }
                })
            },
            changePerson(val){
                // this.schoolPersonIdObject = val;


                console.log(this.schoolPersonIdObject)
            },

            _changeSchool (view) {
                let arr = this.OrgName[view]
                this.SchoolId = arr.Id
                this.SchoolName = arr.OrgName
                this.get_scool_person(1);
            },
            PersonOnChange (selectedRowKeys, selectedRows) {
                this.PersonSelectedRowKeys = selectedRowKeys
                this.PersonSelectedRow = selectedRows
            },
            partTimeOnChange (selectedRowKeys, selectedRows) {
                this.partKeys = selectedRowKeys
                this.partTimeSelectedRow = selectedRows
            },
            _channelSave () {
                //渠道
                let self = this
                this.ChannelId = ''
                this.ParttimeId = ''
                if (this.PersonSelectedRow.length != 0) {
                    for (let i = 0; i < this.PersonSelectedRow.length; i++) {
                        self.ChannelId += self.PersonSelectedRow[i].Id + ','
                    }
                }
                //兼职人员
                if (this.partTimeSelectedRow.length != 0) {
                    for (let j = 0; j < this.partTimeSelectedRow.length; j++) {
                        self.ParttimeId += self.partTimeSelectedRow[j].Id + ','
                    }
                }
                this.channel = []
                this.channel = this.channel.concat(this.PersonSelectedRow)
                this.channel = this.channel.concat(this.partTimeSelectedRow)
                let arr = []
                this.channel.map((res, index) => {
                    res.sNumber = index + 1
                    console.log(res)
                    arr.push(
                        Object.assign(res, {
                            fee: res.ExpectFee
                        })
                    )
                })
                this._channelTabClose()
                // this._back();
            },
            _dataBack () {
                this.isNew = false
                this.viewData = false
                this.channelId = ''
                this.myPageSize = 10
                this.myPageSizeNum = 10
                this._back()
                this._customerResetBtn()
            },
            searchChannelData () {
                this.$message.loading('正在查询...', 0)
                this.$axios.get(
                    8000036,
                    {
                        ActivityId: this.ActivityId,
                        StartDate: this.DataStartDate,
                        EndDate: this.DataEndDate
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.channel = res.data.data
                            this.channel.map((res, index) => {
                                res.sNumber = index + 1
                            })
                            let arr = []
                            this.channelAll = res.data.data
                            this.channelAll.map((res, index) => {
                                res.sNumber = index + 1
                            })
                            this.$message.destroy()
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    }
                )
            }
        }
    }
</script>

<style scoped lang="less">
    .activityWork {
        .select {
            width: 200px;
            margin-right: 10px;
        }

        .create-btn {
            width: 80px;
            float: right;
            margin-right: 30px;
        }

        .info {
            width: 200px;
            margin-right: 10px;
        }

        .reset-btn {
            margin-right: 10px;
        }

        .columns {
            margin-top: 20px;
            margin-right: 20px;
        }
    }

    .related-table {
        .header {
            display: flex;

            .title {
                margin: 25px 0 15px 10px;
                border-left: 3px solid #2994ff;
                padding: 3px 5px;
            }

            .create-btn {
                margin: 25px 10px 0 auto;
            }
        }

        .container {
        }
    }

    .channel-header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .channel-title {
            border-left: 3px solid #2994ff;
            padding: 3px 5px;
        }

        .channel-download {
            font-size: 4px;
            color: #888;
            margin-left: 15px;
        }
    }

    .table {
        .title {
            margin-bottom: 10px;
            border-left: 3px solid #2994ff;
            padding: 3px 5px;
        }
        .header {
            display: flex;
        }
        .bottom-page {
            margin-top: 20px;
            display: flex;
            .bottom {
                display: flex;
                margin-left: auto;
            }
        }
    }
    .input {
        .input_file {
            position: absolute;
            z-index: 100;
            width: 1px;
            height: 1px;
            right: 100px;
            opacity: 0;
        }
    }

    .view-data {
        overflow: hidden;
        .header {
            // display: flex;
            width: 100%;
            padding: 15px;
            background: #fff;
            border-radius: 4px;

            .title {
                margin-right: auto;
                font-size: 16px;
                font-weight: bold;
                display: flex;
                align-items: center;
                width: 100%;
                padding-left: 15px;
                padding-bottom: 15px;
                margin-bottom: 15px;
                border-bottom: 1px solid #ddd;
            }

            .header-info {
                display: flex;
                margin-top: 30px;
            }

            .btn {
                margin-left: auto;
            }
        }

        .table-container {
            width: 100%;
            padding: 15px;
            margin-top: 20px;
            background: #fff;
            border-radius: 4px;
            .table-total span {
                color: #2994ff;
            }
        }
    }

    .row {
        width: 250px;
        margin-left: 13px;
    }

    .alert-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        padding: 10px 16px;
        background: #fff;
        button {
            width: 120px;
        }
    }

    .operation-page {
        width: 100%;
        min-height: 100%;
        padding: 15px;
        background: #fff;
        border-radius: 4px;

        .new-page {
            .new-page-header {
                display: flex;
                align-items: center;
                width: 100%;
                padding-left: 15px;
                padding-bottom: 15px;
                margin-bottom: 15px;
                border-bottom: 1px solid #ddd;
                .title {
                    margin-right: auto;
                    font-size: 16px;
                }

                .btn {
                    margin-left: auto;
                }
            }

            .container {
                margin-top: 30px;
                margin-left: 10px;
                font-size: 15px;
                border-left: 3px solid #2994ff;
                padding-left: 5px;

                .info-container {
                    display: flex;
                    align-items: center;

                    .info-title {
                        width: 70px;
                        white-space: nowrap;
                    }

                    .info-text {
                        width: 320px;
                        height: 40px;
                        margin-left: 25px;
                    }
                }
            }
        }
    }

    .ant-advanced-search-form {
        padding: 25px 25px 5px 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    #components-form-demo-advanced-search .search-result-list {
        margin-top: 16px;
        min-height: 200px;
        text-align: center;
        padding-top: 80px;
    }
</style>
