<template>
  <div class="staffLevel">
    <div v-if="!isNew" class="container">
      <div class="header">
        <!-- <div class="title">兼职等级</div> -->
        <a-input
          style="width:150px"
          placeholder="创建人"
          class="info"
          v-model="CreateUserName"
        />
        <a-input
          style="margin-left:10px;width:200px"
          placeholder="搜索兼职等级名称"
          class="info"
          v-model="Name"
        />
        <a-button style="margin-left:10px" class="reset-btn" @click="resetBtn()"
          >重置</a-button
        >
        <a-button style="margin-left:10px" type="primary" @click="_getInfo()"
          >查询</a-button
        >

        <a-button type="primary" class="create-btn" @click="_showNewPage"
          >新建</a-button
        >
      </div>
      <div class="columns">
        <a-table
          bordered
          :columns="tableColumns"
          :dataSource="tableData"
          :pagination="false"
        >
          <div slot="Operation" slot-scope="text, record, index">
            <i
              class="iconfont icon-bianji icon-btn"
              style="font-size: 20px; margin-right: 10px"
              @click.stop="_setItem(record)"
            ></i>
            <a-popconfirm
              title="您确定要删除吗?"
              @confirm="_delTableRow(record, index)"
              okText="确定"
              cancelText="取消"
            >
              <i
                class="iconfont icon-shanchu- icon-btn"
                style="font-size: 20px"
              ></i>
            </a-popconfirm>
          </div>
        </a-table>
        <div style="margin: 20px 0; text-align: right" v-if="count > 10">
          <a-pagination
            showQuickJumper
            :defaultCurrent="1"
            :total="count"
            @change="_PageChange"
          />
        </div>
      </div>
    </div>
    <div v-if="isNew" class="new-page">
      <div class="new-page-header">
        <div class="title">{{ this.detailData ? '编辑' : '创建' }}兼职等级</div>
        <div class="btn">
          <a-button
            type="primary"
            style="width: 80px; margin-right: 10px"
            @click="_save"
            >确定</a-button
          >
          <a-button style="width: 80px" @click="_back">返回</a-button>
        </div>
      </div>
      <div class="new-page-container">
        <div class="title">基本信息</div>
        <div class="content">
          <div class="row flex">
            <span class="row-label">兼职等级</span>
            <div class="row-input">
              <a-input placeholder="请输入" v-model="className" />
            </div>
          </div>

          <div class="row flex">
            <span class="row-label">基本工资</span>
            <div class="row-input">
              <a-input placeholder="请输入" v-model="baseMoney" />
            </div>
          </div>

          <div class="row flex">
            <span class="row-label">任务目标</span>
            <div class="row-input">
              <a-input placeholder="请输入" v-model="targetMoney" />
            </div>
          </div>

          <div class="row flex">
            <span class="row-label">惩罚机制</span>
            <div class="row-input">
              <a-switch
                checked-children="开"
                un-checked-children="关"
                default-checked
                v-model="punishment"
              />
            </div>
          </div>

          <div class="row flex" style="margin-bottom: 0">
            <span class="row-label"></span>
            <div class="row-input">
              <a-radio-group v-model="punishmentNum">
                <a-radio :value="1" style="margin-right:0">名单比例</a-radio>
                <a-tooltip>
                  <template slot="title"
                    >名单比例=自定义设置比例<br />实际到手工资=（基本工资/任务数）*名单比例*获取名单数
                    例如：基本工资为200，任务数为100个名单，最终获取名单数为80，即如设置名单比例为100%，那么实际工资=（200/100）*100%*80=160元。</template
                  >
                  <a-icon
                    type="question-circle"
                    theme="twoTone"
                    style="margin-right:40px"
                  />
                </a-tooltip>
                <a-radio :value="2" style="margin-right:0">固定扣除</a-radio>
                <a-tooltip>
                  <template slot="title"
                    >固定扣除：自定义扣除金额<br />例如：基本工资为200，任务数为100个名单，最终获取名单数为80，
                    那么固定扣除40元,实际到手工资为160元。</template
                  >
                  <a-icon type="question-circle" theme="twoTone" />
                </a-tooltip>
              </a-radio-group>
            </div>
          </div>

          <div class="row flex" style="margin: 10px 0">
            <span class="row-label"></span>
            <div class="row-input">
              <a-input
                v-if="punishmentNum == 1"
                style="max-width:230px"
                type="number"
                :min="0"
                :max="100"
                addon-before="名单比例"
                addon-after="%"
                v-model="punishmentNum1"
              />
              <a-input
                v-if="punishmentNum == 2"
                style=";max-width:230px"
                type="number"
                :min="0"
                addon-before="扣除金额"
                addon-after="元"
                v-model="punishmentNum2"
              />
            </div>
          </div>

          <div class="row flex">
            <span class="row-label">任务提成</span>
            <div class="row-input">
              <a-radio-group v-model="targetNum">
                <a-radio style="margin-right: 0" :value="1"
                  >超出范围任务目标固定提成</a-radio
                >
                <a-tooltip>
                  <template slot="title"
                    >超出任务目标提成=基本工资+超出任务目标单价<br />例如：基本工资为200，任务数为100个名单，
                    最终获取名单数为110，单个名单为2元，则实际到手工资为200+（10*2）=220元。</template
                  >
                  <a-icon
                    type="question-circle"
                    theme="twoTone"
                    style="margin-right:40px"
                  />
                </a-tooltip>
                <a-radio :value="2" style="margin-right:0"
                  >达到固定数额提成</a-radio
                >
                <a-tooltip>
                  <template slot="title"
                    >达到固定数额提成=基本工资+目标提成（自定义）<br />例如：基本工资为200元，任务数为100个名单，最终获取名单数为200，设置目标提成为达到50个名单，
                    提成100元，达到100个名单，提成200元，那么实际到手工资200+200=400元。</template
                  >
                  <a-icon type="question-circle" theme="twoTone" />
                </a-tooltip>
              </a-radio-group>
            </div>
          </div>

          <div class="row flex" style="margin: 10px 0">
            <span class="row-label"></span>
            <div class="row-input">
              <a-input
                v-if="targetNum == 1"
                style="max-width:200px"
                type="number"
                :min="0"
                addon-before="单个名单"
                addon-after="元"
                v-model="targetNum1"
              />
              <div v-if="targetNum == 2">
                <div
                  v-for="(item, index) in targetList"
                  style="display: flex;align-items: center;margin-bottom: 10px;"
                >
                  <a-input
                    style=";max-width:200px;margin-right: 20px;"
                    type="number"
                    :min="0"
                    addon-before="名单数量"
                    addon-after="个"
                    v-model="item.num"
                  />
                  <a-input
                    style=";max-width:200px"
                    type="number"
                    :min="0"
                    addon-before="有效提成"
                    addon-after="元"
                    v-model="item.fee"
                  />
                  <a-icon
                    type="minus-circle"
                    style="font-size: 20px;color: #aaa;margin-left:10px;cursor: pointer"
                    v-if="index > 0"
                    @click="_delTarget(index)"
                  />
                  <a-icon
                    type="plus-circle"
                    style="font-size: 20px;color: #aaa;margin-left:10px;cursor: pointer"
                    v-if="index == targetList.length - 1"
                    @click="_addTarget"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
export default {
  name: 'staffLevel',
  data () {
    return {
      isNew: false,
      tableColumns: [
        {
          title: '序号',
          dataIndex: 'sNumber',
          width: 80,
          align: 'center'
        },
        {
          title: '兼职等级',
          dataIndex: 'Name',
          width: 400,
          align: 'center'
        },
        {
          title: '基本工资',
          dataIndex: 'BaseSalary',
          width: 250,
          align: 'center'
        },
        {
          title: '任务目标',
          dataIndex: 'TargetNum',
          width: 250,
          align: 'center'
        },
        {
          title: '奖惩机制',
          dataIndex: 'PunishType',
          width: 400,
          align: 'center'
        },
        {
          title: '任务提成',
          dataIndex: 'RoyaltySingleFee',
          width: 400,
          align: 'center'
        },
        {
          title: '创建人',
          dataIndex: 'CreateUserName',
          width: 300,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'CreateTime',
          width: 400,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'Operation',
          width: 200,
          align: 'center',
          scopedSlots: {
            customRender: 'Operation'
          }
        }
      ],
      tableData: [],
      count: 0,
      userInfo: '',
      detailData: '',

      className: '',
      baseMoney: '',
      targetMoney: '',
      punishment: false,
      punishmentNum: 1,
      punishmentNum1: '',
      punishmentNum2: '',
      targetNum: 1,
      targetNum1: '',
      targetNum2: '',
      targetNum3: '',
      targetList: [
        {
          num: '',
          fee: ''
        }
      ],
      CreateUserName: '',
      Name: ''
    }
  },
  created () {
    this._getInfo()
  },
  methods: {
    _delTarget (i) {
      this.targetList.splice(i, 1)
    },
    _addTarget () {
      this.targetList.push({ num: '', fee: '' })
    },
    _showNewPage () {
      this.isNew = true
      this.isNewAdd = 1
    },
    _setItem (i) {
      this.isNew = true
      this.$axios.get(
        8000021,
        {
          Id: i.Id
        },
        res => {
          if (res.data.code == 1) {
            this.detailData = res.data.data
            let data = res.data.data
            this.className = data.Name
            this.baseMoney = data.BaseSalary
            this.targetMoney = data.TargetNum
            this.punishment = data.IsPunish == 1 ? true : false
            this.punishmentNum = data.PunishType
            this.punishmentNum2 = data.FixedDeduct
            this.punishmentNum1 = data.ListPercent
            this.targetNum = data.RoyaltyType
            this.targetNum1 = data.RoyaltySingleFee
            this.targetList = data.RoyaltyFixedFee
            // this.targetNum2 = data.RoyaltyFixedFee?data.RoyaltyFixedFee[0].num:'';
            // this.targetNum3 = data.RoyaltyFixedFee?data.RoyaltyFixedFee[0].fee:'';
          }
        }
      )
    },
    _delTableRow (data, index) {
      console.log(data)
      this.$axios.post(
        8000027,
        {
          Id: data.Id
        },
        res => {
          if (res.data.code == 1) {
            this.$message.success('删除成功')
            this._getInfo()
            // this.tableData.splice(index,1);
          }
        }
      )
    },
    _PageChange (pageNumber) {
      // console.log(pageNumber);
      this.Page = pageNumber
      this.$axios.post(
        8000012,
        {
          Page: this.Page,
          PageSize: 10,
          CreateUserName: this.CreateUserName,
          Name: this.Name
        },
        res => {
          if (res.data.code == 1) {
            this.count = res.data.count
            this.tableData = res.data.data
            this.tableData.map((res, index) => {
              if (this.Page) {
                res.sNumber = (this.Page - 1) * 10 + index + 1
              } else {
                res.sNumber = index + 1
              }
            })
          }
        }
      )
    },
    _save () {
      let data = {
        Name: this.className,
        BaseSalary: this.baseMoney,
        TargetNum: this.targetMoney,
        IsPunish: this.punishment ? 1 : 0,
        PunishType: this.punishmentNum,
        FixedDeduct: this.punishmentNum2,
        ListPercent: this.punishmentNum1,
        RoyaltyType: this.targetNum,
        RoyaltySingleFee: this.targetNum1,
        RoyaltyFixedFee: JSON.stringify(this.targetList),
        UserId: userInfo.uid,
        UserName: userInfo.name
      }
      if (this.detailData) {
        data.Id = this.detailData.Id
        this.$axios.post(8000013, data, res => {
          if (res.data.code == 1) {
            this.$message.success('修改成功')
            this._getInfo()
            this._onClose()
          } else {
            this.$message.error(res.data.msg)
          }
        })
      } else {
        this.$axios.post(8000013, data, res => {
          if (res.data.code == 1) {
            this.$message.success('添加成功')
            this._getInfo()
            this._onClose()
          } else {
            this.$message.error(res.data.msg)
          }
        })
      }
      this._back()
    },
    _back () {
      this.detailData = ''
      this.isNew = false
      this.className = ''
      this.baseMoney = ''
      this.targetMoney = ''
      this.punishment = false
      this.punishmentNum = 1
      this.punishmentNum1 = ''
      this.punishmentNum2 = ''
      this.targetNum = 1
      this.targetNum1 = ''
      this.targetNum2 = ''
      this.targetNum3 = ''
    },
    _getInfo () {
      this.$axios.post(
        8000012,
        {
          Page: this.Page,
          PageSize: 10,
          CreateUserName: this.CreateUserName,
          Name: this.Name
        },
        res => {
          if (res.data.code == 1) {
            this.count = res.data.count
            this.tableData = res.data.data
            this.tableData.map((res, index) => {
              res.sNumber = index + 1
            })
          } else {
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    _onClose () {
      this.isNew = false
      this.detailData = ''
    },
    resetBtn () {
      ;(this.CreateUserName = ''), (this.Name = ''), (this.index = 1)
    }
  }
}
</script>

<style scoped lang="less">
.staffLevel {
  .container {
  }

  .header {
    display: flex;
    align-items: center;
    //margin: 10px 0 0 20px;

    .title {
      margin-right: auto;
      font-size: 16px;
    }

    .create-btn {
      margin-left: auto;
      margin-right: 30px;
      float: right;
      width: 80px;
    }
  }

  .columns {
    margin-top: 20px;
    margin-right: 20px;
  }
}

.new-page {
  /*padding: 15px;*/
  /*margin-top: 10px;*/

  .new-page-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    .title {
      margin-right: auto;
      font-size: 16px;
    }

    .btn {
      margin-left: auto;
    }
  }
}

.info_container {
  display: flex;
}

.row {
  margin-bottom: 20px;

  .label {
    display: inline-block;
    width: 70px;
    white-space: nowrap;
  }

  .row-text {
    width: 350px;
    margin-left: 25px;
  }
}

.alert-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;

  button {
    width: 120px;
  }
}

.new-page-container {
  padding-left: 15px;
  .content {
    padding-left: 15px;
    .row {
      justify-content: end;
      margin: 20px 0;
      .row-label {
        display: inline-block;
        vertical-align: middle;
        width: 80px;
        font-size: 14px;
        color: #5c5c5c;
      }
    }
  }
  .title {
    border-left: 3px solid #1890ff;
    padding: 2px 0 2px 5px;
    line-height: 1.2;
  }
}

.form {
  margin: 20px 0 0 6px;

  .info-input {
    margin-left: 20px;
  }

  .checked-btn {
    margin-left: 22px;
    line-height: 22px;
    width: 60px;
  }

  .selected-first {
    margin-left: 25px;
    margin-top: 15px;
    width: 400px;
    // border: 1px solid red;
  }

  .selected-second {
    margin-left: 25px;
    width: 400px;
  }
}

.task {
  display: flex;
}

.ant-input {
  width: 240px;
}
</style>
