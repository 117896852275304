<template>
  <div id="report">
    <div class="header">
      <a-tree-select
        style="min-width: 150px;margin-right: 10px;"
        :treeData="treeDataSchool"
        treeNodeFilterProp="title"
        :value="schoolVal"
        @change="onChangeSchool"
        treeCheckable
        :showCheckedStrategy="SHOW_PARENT"
        placeholder="中心"
      />
      <!-- 日期组件 -->
      <a-range-picker
        style="width: 200px; margin-right: 10px"
        format="YYYY-MM-DD"
        :placeholder="['开始时间', '结束时间']"
        :ranges="{
          本月: [moment().startOf('month'), moment().endOf('month')],
          上月: [
            moment(
              moment()
                .month(moment().month() - 1)
                .startOf('month')
            ),
            moment(
              moment()
                .month(moment().month() - 1)
                .endOf('month')
            )
          ],
          上上月: [
            moment(
              moment()
                .month(moment().month() - 2)
                .startOf('month')
            ),
            moment(
              moment()
                .month(moment().month() - 2)
                .endOf('month')
            )
          ]
        }"
        @change="ChangeSearchTime"
      >
        <a-tooltip slot="suffix"><a-icon type="clock-circle"/></a-tooltip>
      </a-range-picker>

      <a-button @click="resetBtn()" style="margin: 0 10px">重置</a-button>
      <a-button type="primary" @click="_info(1)">查询</a-button>
    </div>

    <a-table
      bordered
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="false"
      style="margin-top:20px"
    >
      <span slot="SchoolName">
        中心名称
        <a-tooltip>
          <template slot="title">只显示权限范围内的中心</template>
          <a-icon
            type="question-circle"
            theme="twoTone"
            two-tone-color="#199ED8"
          />
        </a-tooltip>
      </span>
      <span slot="yxNum">
        有效名单
        <a-tooltip>
          <template slot="title">即新名单,本表只统计扫码进来的名单</template>
          <a-icon
            type="question-circle"
            theme="twoTone"
            two-tone-color="#199ED8"
          />
        </a-tooltip>
      </span>
      <span slot="existNum">
        重复名单
        <a-tooltip>
          <template slot="title"
            >1.剔除反复扫码名单只计算一次<br />2.系统里已存在的名单</template
          >
          <a-icon
            type="question-circle"
            theme="twoTone"
            two-tone-color="#199ED8"
          />
        </a-tooltip>
      </span>
      <span slot="missmatachNum">
        不符合条件名单
        <a-tooltip>
          <template slot="title"
            >1.结业（结业时间在授权时间之前）<br />2.成交（成交时间在授权时间之前）<br />3.1个月内有员工录入跟踪记录(授权时间之前的1个月)<br />4.员工自己手机号扫码授权名单<br />以上条件满足任意1个</template
          >
          <a-icon
            type="question-circle"
            theme="twoTone"
            two-tone-color="#199ED8"
          />
        </a-tooltip>
      </span>
      <span slot="payed">
        付费客户数/总金额
        <a-tooltip>
          <template slot="title"
            >付费客户数：同一个客户多次下单也只计一次<br />总金额：支付订单加和<br />（只计算小课包的订单）</template
          >
          <a-icon
            type="question-circle"
            theme="twoTone"
            two-tone-color="#199ED8"
          />
        </a-tooltip>
      </span>
      <span slot="missmatchBuyer">
        不符合条件客户/总金额
        <a-tooltip>
          <template slot="title"
            >不符合条件客户<br />付费客户数中<br />1.结业（结业时间在支付时间之前）<br />2.成交（成交时间在支付时间之前）<br />3.员工自己手机号扫码授权名单<br />以上条件满足任意1个<br />总金额：不符合条件客户支付订单加和<br />（只计算小课包的订单）</template
          >
          <a-icon
            type="question-circle"
            theme="twoTone"
            two-tone-color="#199ED8"
          />
        </a-tooltip>
      </span>
      <span slot="yxFeeNum">
        有效付费名单
        <a-tooltip>
          <template slot="title"
            >有效付费名单=订单客户数-不符合条件客户</template
          >
          <a-icon
            type="question-circle"
            theme="twoTone"
            two-tone-color="#199ED8"
          />
        </a-tooltip>
      </span>
      <span slot="notPayedNum">
        非付费名单
        <a-tooltip>
          <template slot="title"
            >非付费名单=有效名单+重复名单-不符合条件名单-付费客户数</template
          >
          <a-icon
            type="question-circle"
            theme="twoTone"
            two-tone-color="#199ED8"
          />
        </a-tooltip>
      </span>
      <div slot="sNumber" slot-scope="text, record, index">
        {{ page * 10 + index + 1 }}
      </div>
    </a-table>
    <div style="margin: 20px 0;text-align: right" v-if="count > 10">
      <a-pagination
        showQuickJumper
        :defaultCurrent="1"
        :total="count"
        @change="_PageChange"
      />
    </div>
  </div>
</template>

<script>
import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
import moment from 'moment'
export default {
  name: 'report',
  data () {
    return {
      centerName: '',
      leaderList: '',
      StartDate: '',
      EndDate: '',
      SearchTel: '',
      parseInfo: '',
      treeDataSchool: [],
      schoolVal: [],
      parseList: [],
      transXY: '',
      count: 0,
      SHOW_PARENT,
      tableData: [],
      tableColumns: [
        {
          title: '序号',
          dataIndex: 'sNumber',
          width: 50,
          align: 'center',
          scopedSlots: {
            customRender: 'sNumber'
          }
        },
        {
          // title: "中心名称",
          dataIndex: 'SchoolName',
          width: 150,
          align: 'center',
          slots: { title: 'SchoolName' }
        },
        {
          // title: "有效名单",
          dataIndex: 'yxNum',
          width: 150,
          align: 'center',
          slots: { title: 'yxNum' }
        },
        {
          // title: "重复名单",
          dataIndex: 'existNum',
          width: 150,
          align: 'center',
          slots: { title: 'existNum' }
        },
        {
          // title: "不符合条件名单",
          dataIndex: 'missmatachNum',
          width: 150,
          align: 'center',
          slots: { title: 'missmatachNum' }
        },
        {
          // title: "付费客户数/总金额",
          dataIndex: 'payed',
          width: 150,
          align: 'center',
          slots: { title: 'payed' }
        },
        {
          // title: "不符合条件客户/总金额",
          dataIndex: 'missmatchBuyer',
          width: 150,
          align: 'center',
          slots: { title: 'missmatchBuyer' }
        },
        {
          // title: "有效付费名单",
          dataIndex: 'yxFeeNum',
          width: 100,
          align: 'center',
          slots: { title: 'yxFeeNum' }
        },
        {
          // title: "非付费名单",
          dataIndex: 'notPayedNum',
          width: 100,
          align: 'center',
          slots: { title: 'notPayedNum' }
        }
      ],
      infoData: '',
      countData: '',
      page: 0
    }
  },
  created () {
    // 获取中心数据接口
    this.$axios.get(8008, {}, res => {
      if (res.data.code == 1) {
        this.treeDataSchool = res.data.data
      } else {
        this.$message.error(res.data.msg)
      }
    })
    // this._info(1);
  },
  mounted () {},
  methods: {
    _info (page) {
      this.$message.loading('查询数据量较大，请耐心等候', 0)
      this.$axios.get(
        8000037,
        {
          SchoolId: this.schoolVal.join(','),
          StartDate: this.StartDate,
          EndDate: this.EndDate,
          page: page,
          limit: 10
        },
        res => {
          if (res.data.code == 1) {
            this.tableData = res.data.data
            // this.countData = res.data.count_data;
            // this.count = res.data.count;
            this.$message.destroy()
          } else {
            if (page == 1) {
              this.tableData = []
              this.count = 0
            }
            this.$message.destroy()
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    moment,
    ChangeSearchTime (time, timeString) {
      this.StartDate = timeString[0]
      this.EndDate = timeString[1]
    },
    resetBtn () {
      this.centerName = ''
      this.SearchTel = ''
      this.schoolVal = []
      this.parseInfo = ''
    },
    onChangeSchool (value) {
      this.schoolVal = value
      this.transXY = {}
    },
    _PageChange (num) {
      this.page = num - 1
      this._info(num)
    }
  }
}
</script>

<style scoped lang="less">
.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  height: 100px;
  width: 80%;
  border: 1px solid #e4e5e6;
  border-radius: 4px;
  .content-icon {
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    line-height: 60px;
    margin: 0 30px;
    border-radius: 50%;
    text-align: center;
    background: #2994ff;
    i {
      font-size: 30px;
      color: #fff;
    }
  }
  .info-main {
    display: flex;
    flex-direction: column;
    flex: 1;
    .title {
      white-space: nowrap;
      font-size: 14px;
      color: #999999;
    }
    .content {
      color: #2994ff;
      font-size: 14px;
    }
  }
}
</style>
