<template>
    <div class="management">
        <a-select
                class="select"
                v-model="SearchCity"
                placeholder="城市"
                @change="_ChangeCity"
        >
            <a-select-option disabled selected hidden value="">城市</a-select-option>
            <a-select-option
                    :value="item.CityName"
                    v-for="(item, index) in CityName"
            >{{ item.CityName }}
            </a-select-option
            >
        </a-select>

        <a-select class="select" v-model="SearchName" placeholder="中心">
            <a-select-option disabled selected hidden value="">中心</a-select-option>
            <a-select-option :value="item.Id" v-for="(item, index) in OrgName">{{
                item.OrgName
                }}
            </a-select-option>
        </a-select>

        <a-select class="select" v-model="SearchLevel" placeholder="等级">
            <a-select-option disabled selected hidden value="">等级</a-select-option>
            <a-select-option :value="item.Id" v-for="item in levelList">{{
                item.Name
                }}
            </a-select-option>
        </a-select>
        <a-input placeholder="搜索姓名/手机号" class="info" v-model="Keyword"/>
        <a-button class="reset-btn" @click="resetBtn()">重置</a-button>
        <a-button type="primary" @click="_searchData">查询</a-button>
        <div class="columns">
            <a-table
                    bordered
                    :columns="tableColumns"
                    :dataSource="tableData"
                    :pagination="false"
            >
                <div slot="Operation" slot-scope="text, index">
                    <i
                            class="iconfont icon-bianji icon-btn"
                            style="font-size: 20px;margin-right: 10px"
                            @click.stop="_setItem(index)"
                    ></i>
                    <a-popconfirm
                            title="您确定要删除吗?"
                            @confirm="_delTableRow(index)"
                            okText="确定"
                            cancelText="取消"
                    >
                        <i
                                class="iconfont icon-shanchu- icon-btn"
                                style="font-size: 20px;"
                        ></i>
                    </a-popconfirm>
                </div>
            </a-table>
            <div style="margin: 20px 0; text-align: right" v-if="count > 10">
                <a-pagination
                        showQuickJumper
                        :defaultCurrent="1"
                        :total="count"
                        @change="_pageSchedule"
                />
            </div>
        </div>
        <a-drawer
                :title="'兼职信息'"
                placement="right"
                width="500"
                :closable="false"
                @close="_onClose"
                :visible="visible"
        >
            <div style="padding-bottom: 60px">
                <p class="row">
                    <span class="label">姓名</span>
                    <a-avatar
                            icon="user"
                            style="margin-left:10px;width: 40px;height: 40px;"
                            :src="this.Avatar"
                    ></a-avatar>
                    <span style="margin-left:10px">{{ setData.Name }}</span>
                </p>
                <p class="row">
                    <span class="label">手机号</span>
                    <span class="row-text">{{ setData.Mobile }}</span>
                </p>
                <p class="row">
                    <span class="label">性别</span>
                    <span class="row-text">{{
            setData.Sex == '0' ? '男' : setData.Sex == '1' ? '女' : ''
          }}</span>
                </p>
                <p class="row">
                    <span class="label">身份证</span>
                    <span class="row-text">{{ setData.IdCardNumber }}</span>
                </p>
                <!-- 身份证照片 -->
                <p class="row">
          <span class="id-card">
            <viewer :images="[this.IdCardPicFront]">
              <img
                      style="width:150px;height:180px;margin-right:30px"
                      :src="this.IdCardPicFront"
              />
            </viewer>
            <viewer :images="[this.IdCardPicBack]">
              <img
                      style="width:150px;height:180px;"
                      :src="this.IdCardPicBack"
              />
            </viewer>
          </span>
                </p>
                <p class="row">
                    <span class="label">等级</span>
                    <a-select
                            class="row-text"
                            v-model="Level"
                            placeholder="请选择"
                            @change="_changeLevel"
                    >
                        <a-select-option
                                :value="item.Name"
                                :levelId="item.Id"
                                v-for="(item, index) in setLevel"
                                :key="index"
                        >{{ item.Name }}
                        </a-select-option
                        >
                    </a-select>
                </p>
                <p class="row">
                    <span class="label">城市</span>
                    <a-select
                            class="row-text"
                            v-model="City"
                            placeholder="请选择"
                            @change="_ChangeCity"
                    >
                        <a-select-option
                                :value="item.CityName"
                                v-for="(item, index) in CityName"
                        >{{ item.CityName }}
                        </a-select-option
                        >
                    </a-select>
                </p>
                <p class="row">
                    <span class="label">中心</span>
                    <a-select
                            class="row-text"
                            v-model="SchoolName"
                            placeholder="请选择"
                            @change="_changeOrg"
                    >
                        <a-select-option v-for="(item, index) in OrgName" :key="index">{{
                            item.OrgName
                            }}
                        </a-select-option>
                    </a-select>
                </p>
                <p class="row">
                    <span class="label">状态</span>
                    <a-select class="row-text" v-model="Status" placeholder="请选择">
                        <a-select-option
                                :value="item"
                                v-for="(item, index) in statusList"
                        >{{ item }}
                        </a-select-option
                        >
                    </a-select>
                </p>
            </div>
            <div class="alert-footer">
                <a-button type="primary" style="margin-right:25px" @click="_save"
                >确定
                </a-button
                >
                <a-button @click="_onClose">取消</a-button>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    import Vue from 'vue'
    import Viewer from 'v-viewer' //图片操作
    import 'viewerjs/dist/viewer.css'

    Vue.use(Viewer)
    const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
    export default {
        name: 'management',
        data() {
            return {
                CityName: [],
                OrgName: [],
                tableData: [],
                levelList: [],
                SchoolId: '',
                statusList: ['试用', '在岗', '停用'],
                NameId: '',
                Level: '',
                City: '',
                showViewer: true,
                SchoolName: '',
                Status: '',
                SearchCity: '',
                SearchName: '',
                SearchLevel: '',
                Keyword: '',
                page: 1,
                Avatar: '',
                CityArr: undefined,
                OrgArr: undefined,
                LevelArr: undefined,
                StateArr: undefined,
                IdCardPicFront: '',
                IdCardPicBack: '',
                tableDataCount: 10,
                searchClass: '',
                count: 0,
                setData: '',
                setLevel: '',
                visible: false,
                sNumber: 0,
                tableColumns: [
                    {
                        title: '序号',
                        dataIndex: 'sNumber',
                        width: 80,
                        align: 'center'
                    },
                    {
                        title: '姓名',
                        dataIndex: 'Name',
                        width: 150,
                        align: 'center'
                    },
                    {
                        title: '手机号',
                        dataIndex: 'Mobile',
                        width: 200,
                        align: 'center'
                    },
                    {
                        title: '等级',
                        dataIndex: 'Level',
                        width: 200,
                        align: 'center'
                    },
                    {
                        title: '状态',
                        dataIndex: 'Status',
                        width: 150,
                        align: 'center'
                    },
                    {
                        title: '城市',
                        dataIndex: 'City',
                        width: 150,
                        align: 'center'
                    },
                    {
                        title: '所属中心',
                        dataIndex: 'SchoolName',
                        width: 250,
                        align: 'center'
                    },
                    {
                        title: '最后申请时间',
                        dataIndex: 'CreateTime',
                        width: 220,
                        align: 'center'
                    },
                    {
                        title: '审批结果',
                        dataIndex: 'Check',
                        width: 120,
                        align: 'center'
                    },
                    {
                        title: '操作',
                        dataIndex: 'Operation',
                        width: 200,
                        align: 'center',
                        scopedSlots: {
                            customRender: 'Operation'
                        }
                    }
                ]
            }
        },
        created() {
            this._getInfo()
        },
        mounted() {
            this.$axios.post(8000002, {}, res => {
                if (res.data.code == 1) {
                    this.CityName = res.data.data
                }
            })
            this.$axios.post(8000009, {}, res => {
                if (res.data.code == 1) {
                    this.levelList = res.data.data
                    this.NameId = res.data.data.Id
                }
            })
        },
        methods: {
            _getInfo() {
                //初始化列表
                this.$axios.post(
                    8000001,
                    {
                        Page: this.page,
                        PageSize: 10
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.tableData = res.data.data
                            this.count = res.data.count
                            this.SchoolId = res.data.data.SchoolId
                            this.tableData.map((res, index) => {
                                res.sNumber = index + 1
                            })
                        }
                    }
                )
            },
            _setItem(i) {
                this.visible = true
                this.$axios.get(
                    8000004,
                    {
                        Id: i.Id
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.setData = res.data.data
                            this.setLevel = res.data.level
                            this.Level = res.data.data.Level
                            this.LevelId = res.data.data.LevelId
                            this.City = res.data.data.City
                            this.SchoolName = res.data.data.SchoolName
                            this.SchoolId = res.data.data.SchoolId
                            this.IdCardPicFront = res.data.data.IdCardPicFront
                            this.IdCardPicBack = res.data.data.IdCardPicBack
                            this.Avatar = res.data.data.Avatar
                            this.Status =
                                res.data.data.Status == 1
                                    ? '试用'
                                    : res.data.data.Status == 2
                                    ? '在岗'
                                    : res.data.data.Status == 3
                                        ? '停用'
                                        : ''
                            this.$axios.post(
                                8000006,
                                {
                                    CityName: this.City
                                },
                                res => {
                                    if (res.data.code == 1) {
                                        this.OrgName = res.data.data
                                    }
                                }
                            )
                        }
                    }
                )
            },
            resetBtn() {
                this.SearchCity = ''
                this.SearchName = ''
                this.SearchLevel = ''
                this.Keyword = ''
                this.OrgName = []
                this.index = 1
            },
            _searchData(page) {
                this.$axios.post(
                    8000001,
                    {
                        City: this.SearchCity,
                        SchoolId: this.SearchName,
                        Keyword: this.Keyword,
                        Level: this.SearchLevel,
                        Page: page,
                        PageSize: 10
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.tableData = res.data.data
                            this.count = res.data.count
                            this.tableData.map((res, index) => {
                                if (page * 0 != 0) {
                                    res.sNumber = index + 1
                                } else {
                                    res.sNumber = (page - 1) * 10 + index + 1
                                }
                            })
                        } else {
                            this.tableData = []
                            this.count = 0
                        }
                    }
                )
            },
            _save() {
                console.log(this.LevelId)
                let data = {
                    Id: this.setData.Id,
                    Level: this.Level,
                    LevelId: this.LevelId,
                    City: this.City,
                    SchoolId: this.SchoolId,
                    SchoolName: this.SchoolName,
                    Status:
                        this.Status == '试用'
                            ? 1
                            : this.Status == '在岗'
                            ? 2
                            : this.Status == '停用'
                                ? 3
                                : ''
                }
                console.log(data)
                this.$axios.post(8000008, data, res => {
                    if (res.data.code == 1) {
                        this.$message.success('修改成功')
                        this._getInfo()
                        this._onClose()
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            },
            _onClose() {
                this.visible = false
                this.LevelArr = undefined
                this.CityArr = undefined
                this.OrgArr = undefined
                this.StateArr = undefined
                this.OrgName = ''
            },
            _pageSchedule(page) {
                this._searchData(page)
            },
            _ChangeCity(value) {
                console.log(value)
                this.SearchName = ''
                this.SchoolName = ''
                this.searcheStores = undefined
                this.$axios.post(
                    8000006,
                    {
                        CityName: value
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.OrgName = res.data.data
                        }
                    }
                )
            },
            _delTableRow(index) {
                console.log(index)
                this.$axios.post(
                    8000019,
                    {
                        Id: index.Id
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.$message.success('删除成功')
                            // this.tableData.splice(index, 1);
                            this._getInfo()
                        }
                    }
                )
            },
            _changeOrg(v) {
                let arr = this.OrgName[v]
                this.SchoolId = arr.Id
                this.SchoolName = arr.OrgName
            },
            _changeLevel(value, options) {
                // console.log(value);
                this.LevelId = options.data.attrs.levelId
                console.log(options.data.attrs.levelId)
            }
        }
    }
</script>

<style scoped lang="less">
    .management {
        .select {
            width: 150px;
            margin-right: 10px;
        }

        .info {
            width: 200px;
        }

        .reset-btn {
            margin: 0 10px 0 10px;
        }

        .columns {
            margin-top: 20px;
            margin-right: 20px;
        }
    }

    .row {
        margin-bottom: 20px;

        .label {
            display: inline-block;
            width: 70px;
            white-space: nowrap;
        }

        .row-text {
            width: 350px;
            margin-left: 15px;
        }

        .id-card {
            margin-left: 90px;
            display: flex;
        }
    }

    .alert-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        padding: 10px 16px;
        background: #fff;

        button {
            width: 120px;
        }
    }
</style>
